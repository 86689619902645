import React from "react";

import { InfoOutlined } from "@mui/icons-material";
import { InputLabel as MInputLabel, Typography } from "@mui/material";

import { TooltipIconButton } from "uiKit/buttons/tooltipIconButton";
import { useIconStyles } from "uiKit/inputs/textField/styles";
import { InputLabelProps } from "uiKit/labels/inputLabel/intefaces";
import { useInputLabelStyles } from "uiKit/labels/inputLabel/styles";

export const InputLabel: React.FC<InputLabelProps> = ({
    label,
    inputInfoText,
    readonly,
    optionalText,
    htmlFor,
    ...props
}) => {
    const { classes } = useInputLabelStyles({ readonly });
    const { classes: iconClasses } = useIconStyles();

    if (!label) {
        return null;
    }

    return (
        <MInputLabel classes={classes} htmlFor={htmlFor} {...props}>
            <Typography variant="subtitle2" className="input-label">
                {label}
            </Typography>
            {optionalText && (
                <Typography variant="caption" className="input-optional-text">
                    {optionalText}
                </Typography>
            )}
            {inputInfoText && (
                <TooltipIconButton
                    variant="default"
                    className={iconClasses.root}
                    title={inputInfoText}
                    icon={InfoOutlined}
                    size="s"
                />
            )}
        </MInputLabel>
    );
};
