import { alpha } from "@mui/material";

import { makeStyles } from "tss-react/mui";

import {
    ApplicationColorPalette,
    TxtColor,
    applicationPalettes,
    applicationShadowPalettes,
} from "shared/theme/defaultTheme";

interface IconButtonStyleProps {
    noShadow?: boolean;
    variant: ApplicationColorPalette;
}

export const useIconButtonStyles = makeStyles<IconButtonStyleProps>()((theme, { noShadow, variant }) => ({
    icon: {
        fontSize: "inherit",
        color: "inherit",
    },
    root: {
        fontSize: 24,
        boxShadow: noShadow ? "none" : applicationShadowPalettes.default[16],
        color: variant === "default" ? TxtColor.Txt4 : applicationPalettes[variant]["400"],
        cursor: "pointer",
        padding: theme.spacing(1),
        "@media (hover: hover)": {
            "&:hover": {
                boxShadow: noShadow ? "none" : applicationShadowPalettes.default[4],
                backgroundColor:
                    variant === "default"
                        ? alpha(applicationPalettes.primary["500"], 0.12)
                        : alpha(applicationPalettes[variant]["500"], 0.12),
                color: variant === "default" ? applicationPalettes.primary["500"] : applicationPalettes[variant]["700"],
            },
        },
        "&:active": {
            boxShadow: noShadow ? "none" : applicationShadowPalettes.default[2],
            backgroundColor:
                variant === "default"
                    ? alpha(applicationPalettes.primary["500"], 0.16)
                    : alpha(applicationPalettes[variant]["500"], 0.16),
            color: variant === "default" ? applicationPalettes.primary["700"] : applicationPalettes[variant]["900"],
        },
        "&.Mui-disabled": {
            color: variant === "default" ? TxtColor.Txt5 : applicationPalettes[variant]["200"],
        },
    },
    sizeSmall: {
        padding: theme.spacing(0.5),
        fontSize: 18,
    },
    disabled: {
        boxShadow: noShadow ? "none" : applicationShadowPalettes.default[1],
    },
}));
