import React from "react";
import ReactDOM from "react-dom/client";

import routeNames from "shared/routes/routeNames";

import App from "./App.tsx";

window.addEventListener("pageshow", e => {
    if (e.persisted) {
        document.location = routeNames.PORTAL.HOME;
    }
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
