import React from "react";

import { Grid } from "@mui/material";

import { useErrorStyles } from "layout/errorLayout/styles";
import { Footer } from "layout/footer";

interface ErrorComponentContainerProps {
    imgSrc?: string;
    children: React.ReactNode;
}

export const ErrorLayout = ({ imgSrc, children }: ErrorComponentContainerProps) => {
    const { classes } = useErrorStyles();

    return (
        <div className={classes.root}>
            <Grid item className={classes.backgroundImage} sm={6} xs={8}>
                <img src={imgSrc} alt="bg" />
            </Grid>
            <Grid
                container
                className={classes.body}
                item
                direction="column"
                justifyContent="space-between"
                wrap="nowrap"
                alignItems="center"
                xs={12}
                md={7}
                spacing={0}
            >
                {children}
                <Grid item>
                    <Footer />
                </Grid>
            </Grid>
        </div>
    );
};
