import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { observer } from "mobx-react-lite";
import { FormConfirmationCode } from "uiKit/inputs/confirmationCode";

import { SignInStore } from "pages/login/store";
import { useLocalize } from "shared/hooks/useLocalize";
import { useStore } from "shared/hooks/useStore";
import { ValidationLocalizationId } from "shared/validation/validation.localization";

interface SignInFormValues {
    confirmationCode: string;
}

const FormPhoneCodeElement = () => {
    const localize = useLocalize();

    const {
        store: { hasErrors },
    } = useStore(SignInStore);

    const {
        formState: { errors: formErrors },
        control,
        setError,
    } = useFormContext<SignInFormValues>();

    const errorMessage = useMemo(() => localize(ValidationLocalizationId.ExpiredCodeValidatorKey), [localize]);

    useEffect(() => {
        if (hasErrors) {
            setError("confirmationCode", {
                type: "manual",
                message: errorMessage,
            });
        }
    }, [errorMessage, setError, hasErrors]);

    return <FormConfirmationCode name="confirmationCode" errors={formErrors} control={control} autoFocus />;
};

export const FormPhoneCode = observer(FormPhoneCodeElement);
