import React from "react";

import { Typography } from "@mui/material";

import { ButtonTextProps } from "uiKit/buttons/interfaces";
import { useButtonTextStyles } from "uiKit/buttons/text/styles";

export const ButtonText: React.FC<ButtonTextProps> = ({ label, textVariant = "button2" }: ButtonTextProps) => {
    const { classes } = useButtonTextStyles();
    return (
        <Typography variant={textVariant} className={classes.buttonTextType}>
            {label}
        </Typography>
    );
};
