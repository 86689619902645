import { outlinedInputClasses } from "@mui/material/OutlinedInput";

import { makeStyles } from "tss-react/mui";
import { ElementPaddingControl, ElementWidthControl } from "uiKit/interfaces";
import { scrollStyles } from "uiKit/styles";

import { TxtColor, applicationPalettes, applicationShadowPalettes, tabletBreakpoint } from "shared/theme/defaultTheme";

interface InputStylesProps {
    readonly?: boolean;
}

export const useInputStyles = makeStyles<InputStylesProps & ElementPaddingControl>()((theme, props) => ({
    input: {
        height: props.height || "32px",
        padding: 0,
        textOverflow: props.readonly ? "ellipsis" : "clip",
        "&::placeholder, &:placeholder-shown": {
            color: TxtColor.Txt4,
            opacity: 1,
            textOverflow: "ellipsis",
        },
        "&.Mui-disabled": {
            // for incorrect text color for the disabled input field in iOS
            opacity: 1,
            WebkitTextFillColor: props.readonly ? TxtColor.Txt1 : TxtColor.Txt6,

            "&::placeholder": { color: "transparent" },
        },
    },
    root: {
        borderRadius: "8px",
        width: "100%",
        padding: props.padding,
        [`&.${outlinedInputClasses.multiline}`]: {
            padding: theme.spacing(0.5),
        },
        "&:hover": {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
                border: "1px solid",
                borderColor: applicationPalettes.primary[500],
            },
        },
        "&.Mui-focused": {
            caretColor: "#4070F4",
            [`& .${outlinedInputClasses.notchedOutline}`]: {
                border: "2px solid",
                borderColor: applicationPalettes.primary[300],
                boxSizing: "border-box",
            },
        },
        "&.Mui-disabled": {
            "&:hover": {
                [`& .${outlinedInputClasses.notchedOutline}`]: {
                    borderColor: props.readonly ? applicationPalettes.default[1] : TxtColor.Txt6,
                },
            },

            [`& .${outlinedInputClasses.notchedOutline}`]: {
                borderColor: props.readonly ? applicationPalettes.default[1] : TxtColor.Txt6,
                color: TxtColor.Txt6,
            },
        },
        "&.Mui-error": {
            "& .inputErrorIcon": {
                fontSize: "20px",
                margin: theme.spacing(0.25),
                color: applicationPalettes.red[500],
                boxShadow: applicationShadowPalettes.red[8],
                borderRadius: "12px",
            },
            borderColor: applicationPalettes.red[300],
        },
        "& .pickerButton": { padding: "0px" },
    },
    adornedEnd: {
        paddingRight: theme.spacing(2),
    },
    multiline: {},
    disabled: {
        "& .MuiInputBase-input": {
            color: TxtColor.Txt6,
        },

        "& textarea": {
            color: TxtColor.Txt6,
        },

        backgroundColor: props.readonly ? applicationPalettes.default["1"] : "transparent",
        "&::placeholder": { color: "transparent" },
    },
    error: {
        padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
    },
    focused: {},
    notchedOutline: {
        borderColor: props.readonly ? applicationPalettes.default[1] : TxtColor.Txt6,
    },
}));

export const useTextFieldStyles = makeStyles<ElementWidthControl>()((theme, props) => ({
    root: {
        display: "flex",
        width: "100%",
        maxWidth: "100%",
        minWidth: "100%",
        [theme.breakpoints.up(tabletBreakpoint)]: {
            width: props.width,
            maxWidth: props.maxWidth,
            minWidth: props.minWidth,
        },
        "& textarea": {
            minHeight: "24px",
            padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
            resize: "vertical",
            overflow: "auto",
            ...scrollStyles,
        },
        "& input": {
            // disable iOS devices auto-zoom on focus text input
            "@supports (-webkit-overflow-scrolling: touch)": {
                fontSize: 16,
            },
        },
    },
}));

export const useStartAdornmentStyles = makeStyles()(theme => ({
    positionStart: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(0.25),
        color: TxtColor.Txt4,
    },
}));

export const useIconStyles = makeStyles()(() => ({
    root: {
        display: "inline-block",
    },
}));
