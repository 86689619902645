import React, { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { TwoColumnLayout } from "layout/twoColumnLayout";
import { observer } from "mobx-react-lite";

import { LoginStep } from "pages/login/constants/enums";
import { SignUpStepHeader } from "pages/login/signUpStepHeader";
import { AuthWay } from "pages/login/steps/authWay";
import { AuthWayLocalizationId } from "pages/login/steps/authWay/localization";
import { CheckInbox } from "pages/login/steps/checkInbox";
import { CheckInboxLocalizationId } from "pages/login/steps/checkInbox/localization";
import { ChooseAccount } from "pages/login/steps/chooseAccount";
import { ChooseAccountLocalizationId } from "pages/login/steps/chooseAccount/localization";
import { EnterCredentials } from "pages/login/steps/enterCredentials";
import { LoginWithCredentialsLocalizationId } from "pages/login/steps/enterCredentials/localization";
import { EnterEmail } from "pages/login/steps/enterEmail";
import { EnterEmailLocalizationId } from "pages/login/steps/enterEmail/localization";
import { EnterPhoneCode } from "pages/login/steps/enterPhoneCode";
import { PhoneCodeLocalizationId } from "pages/login/steps/enterPhoneCode/localization";
import { ProcessEmailToken } from "pages/login/steps/processEmailToken";
import { VerifyEmail } from "pages/login/steps/verifyEmail";
import { VerifyEmailLocalizationId } from "pages/login/steps/verifyEmail/localization";
import { SignInStore } from "pages/login/store";
import { LocalizeFunction, useLocalize } from "shared/hooks/useLocalize";
import { useStore } from "shared/hooks/useStore";
import { LocalizedComponentName } from "shared/localization/enums";
import { withLocalizations } from "shared/localization/withLocalization";

type StepDetailsType = {
    [index: number]: { header?: React.ReactNode; component: React.ReactNode; image: string };
};

const getSteps = (localize: LocalizeFunction): StepDetailsType => {
    return {
        [LoginStep.EnterEmail]: {
            header: <SignUpStepHeader />,
            component: <EnterEmail />,
            image: localize(EnterEmailLocalizationId.StepPicture),
        },
        [LoginStep.ChooseAuthenticationMethod]: {
            header: <SignUpStepHeader />,
            component: <AuthWay />,
            image: localize(AuthWayLocalizationId.StepPicture),
        },
        [LoginStep.CheckEmailInbox]: {
            component: <CheckInbox />,
            image: localize(CheckInboxLocalizationId.StepPicture),
        },
        [LoginStep.EnterPhoneCode]: {
            component: <EnterPhoneCode />,
            image: localize(PhoneCodeLocalizationId.StepPicture),
        },
        [LoginStep.ProcessEmailToken]: {
            component: <ProcessEmailToken />,
            image: localize(PhoneCodeLocalizationId.StepPicture),
        },
        [LoginStep.EnterCredentials]: {
            header: <SignUpStepHeader />,
            component: <EnterCredentials />,
            image: localize(LoginWithCredentialsLocalizationId.StepPicture),
        },
        [LoginStep.VerifyEmail]: {
            component: <VerifyEmail />,
            image: localize(VerifyEmailLocalizationId.StepPicture),
        },
        [LoginStep.CheckVerificationEmailInbox]: {
            component: <CheckInbox />,
            image: localize(CheckInboxLocalizationId.StepPicture),
        },
        [LoginStep.ChooseAccount]: {
            component: <ChooseAccount />,
            image: localize(ChooseAccountLocalizationId.StepPicture),
        },
    };
};

const LoginElement = () => {
    const localize = useLocalize();
    const [search] = useSearchParams();
    const returnUrl = search.get("returnUrl");

    const {
        store: {
            loginState: { activeStep },
            setReturnUrl,
            setStep,
        },
    } = useStore(SignInStore);

    const stepDetails = useMemo(() => {
        return getSteps(localize)[activeStep];
    }, [activeStep, localize]);

    useEffect(() => {
        if (returnUrl) {
            setReturnUrl(returnUrl);
        }
        if (search.has("token")) {
            setStep(LoginStep.ProcessEmailToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <TwoColumnLayout imgSrc={stepDetails.image} content={stepDetails.component} header={stepDetails.header} />;
};

export const Login = withLocalizations(observer(LoginElement), [
    LocalizedComponentName.SignIn,
    LocalizedComponentName.SignInEnterEmail,
    LocalizedComponentName.SignInEnterCredentials,
    LocalizedComponentName.SignInAuthWay,
    LocalizedComponentName.SignInCheckInbox,
    LocalizedComponentName.SignInPhoneCode,
    LocalizedComponentName.SignInChooseAccount,
    LocalizedComponentName.SignInVerifyEmail,
]);
