/**
 * Summary: Logout.
 *
 * Tags: Ui
 */
import { AxiosRequestConfig } from "axios";

import { httpPost } from "shared/api/apiHandler";

export const API_POST_UI_LOGOUT_URL = "api/v1/ui/logout";

export const apiPostUiLogout = async (
    query?: ApiPostUiLogoutQuery,
    config?: AxiosRequestConfig
): Promise<ApiPostUiLogoutResponse> => {
    const result = await httpPost<ApiPostUiLogoutResponse>("", API_POST_UI_LOGOUT_URL, undefined, {
        ...config,
        params: query,
    });
    return result.data;
};

/**
 *
 */
export interface ApiPostUiLogoutQuery {
    logoutId?: string; // Logout Id. Example: 1
}

/**
 * Description: Success
 */
export type ApiPostUiLogoutResponse = LogoutResponse;

interface LogoutResponse {
    iframeUrl?: string; // IFrame url. Example: https://localhost:5001/signout
    postLogoutRedirectUri?: string; // Post logout redirect url. Example: https://localhost:5001/home
}
