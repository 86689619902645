import React, { useCallback, useMemo } from "react";

import { Typography } from "@mui/material";

import { observer } from "mobx-react-lite";
import { Form } from "uiKit/containers/form";
import { SubmitHandler } from "uiKit/containers/form/interfaces";

import { ErrorNotification } from "pages/login/errorNotification";
import { NavigationButtons } from "pages/login/navigationButtons";
import { LoginStepContent } from "pages/login/stepContent";
import { EnterCredentialsFormContent } from "pages/login/steps/enterCredentials/formContent";
import { LoginWithCredentialsLocalizationId } from "pages/login/steps/enterCredentials/localization";
import {
    EnterCredentialsFormValues,
    createLoginWithCredentialsSchema,
} from "pages/login/steps/enterCredentials/schema";
import { useEnterCredentialsStyles } from "pages/login/steps/enterCredentials/styles";
import { SignInStore } from "pages/login/store";
import { useLocalize } from "shared/hooks/useLocalize";
import { useRequestContext } from "shared/hooks/useRequest";
import { useStore } from "shared/hooks/useStore";

const EnterCredentialsElement = (): React.ReactElement => {
    const { classes } = useEnterCredentialsStyles();
    const localize = useLocalize();
    const { executeRequest } = useRequestContext();

    const {
        store: { validateCredentials, credentials },
    } = useStore(SignInStore);

    const schema = useMemo(() => createLoginWithCredentialsSchema(localize), [localize]);

    const onSubmit: SubmitHandler<EnterCredentialsFormValues> = useCallback(
        async data => {
            await executeRequest(validateCredentials, "", [data]);
        },
        [executeRequest, validateCredentials]
    );

    return (
        <LoginStepContent>
            <div className={classes.title}>
                <Typography variant="h1">{localize(LoginWithCredentialsLocalizationId.LogInTitle)}&nbsp;</Typography>
                <Typography variant="h1">{localize(LoginWithCredentialsLocalizationId.CredentialsTitle)}</Typography>
            </div>
            <ErrorNotification />
            <Form schema={schema} onHandleSubmit={onSubmit} className={classes.form} defaultValues={credentials}>
                <EnterCredentialsFormContent />
                <NavigationButtons
                    submitButtonLabel={localize(LoginWithCredentialsLocalizationId.LoginButton)}
                    backButtonLabel={localize(LoginWithCredentialsLocalizationId.BackButton)}
                />
            </Form>
        </LoginStepContent>
    );
};
export const EnterCredentials = observer(EnterCredentialsElement);
