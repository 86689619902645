import { Button, CircularProgress } from "@mui/material";

import { CommonResizableButtonsProps } from "uiKit/buttons/interfaces";
import { useSolidButtonStyles } from "uiKit/buttons/solidButton/styles";
import { buttonSizeToTextVariantMapping, getButtonSpinnerSize, useButtonProgressStyles } from "uiKit/buttons/styles";
import { ButtonText } from "uiKit/buttons/text/ButtonText";

import { useDoubleClickPreventer } from "shared/hooks/useDoubleClickPreventer";

export const SolidButton = ({
    id,
    label,
    palette,
    size = "m",
    startIcon,
    endIcon,
    disabled,
    isLoading,
    href,
    openInNewTab,
    onClick,
    ...rest
}: CommonResizableButtonsProps) => {
    const { classes } = useSolidButtonStyles({ palette: palette || "default", size });
    const { classes: progressClasses } = useButtonProgressStyles();
    const variant = size && buttonSizeToTextVariantMapping[size];
    const additionalProps = href
        ? { href, target: openInNewTab ? "_blank" : "_self", className: rest.className }
        : rest;
    const [isClickProcessing, onClickHandler] = useDoubleClickPreventer(onClick);

    return (
        <Button
            id={id}
            {...additionalProps}
            onClick={onClickHandler}
            disabled={isLoading || disabled || isClickProcessing}
            startIcon={startIcon}
            endIcon={endIcon}
            variant="contained"
            focusRipple={false}
            classes={classes}
            disableElevation
        >
            <ButtonText label={label} textVariant={variant} />
            {isLoading && <CircularProgress size={getButtonSpinnerSize(size)} className={progressClasses.root} />}
        </Button>
    );
};
