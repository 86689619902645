import { useCallback, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { EmailVerificationErrorCodes } from "pages/verifyEmail/constants";
import { VerifyEmailStore } from "pages/verifyEmail/store";
import { isAxios400Error } from "shared/api/utils";
import { useStore } from "shared/hooks/useStore";
import routeNames from "shared/routes/routeNames";

export const useTokenProcessor = () => {
    const [search] = useSearchParams();
    const token = search.get("token");
    const navigate = useNavigate();
    const [isProcessingToken, setProcessingToken] = useState(true);

    const {
        store: { verifyEmailByToken },
    } = useStore(VerifyEmailStore);

    const handleError = useCallback(
        (e: unknown) => {
            if (!isAxios400Error(e)) {
                return;
            }

            if (
                e.response.data.errors.some(
                    it => it.code === EmailVerificationErrorCodes.UserEmailVerificationTokenExpired
                )
            ) {
                return navigate({
                    pathname: routeNames.ERRORS.EXPIRED_EMAIL_VERIFICATION_LINK,
                    search: search.toString(),
                });
            } else {
                return navigate(routeNames.ERRORS.NOT_FOUND);
            }
        },
        [navigate, search]
    );

    const processToken = useCallback(async () => {
        setProcessingToken(true);

        try {
            if (!token) {
                return navigate(routeNames.ERRORS.NOT_FOUND);
            }

            await verifyEmailByToken(token);
        } catch (e) {
            handleError(e);
        } finally {
            setProcessingToken(false);
        }
    }, [token, navigate, verifyEmailByToken, handleError]);

    return { processToken, isProcessingToken };
};
