import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { FormTextField } from "uiKit/inputs/textField";

import { VerifyEmailLocalizationId } from "pages/login/steps/verifyEmail/localization";
import { VerifyEmailFormValues } from "pages/login/steps/verifyEmail/schema";
import { SignInStore } from "pages/login/store";
import { useLocalize } from "shared/hooks/useLocalize";
import { useStore } from "shared/hooks/useStore";

export const FormEnterEmail = () => {
    const localize = useLocalize();
    const {
        control,
        formState: { errors },
        getValues,
    } = useFormContext<VerifyEmailFormValues>();

    const {
        store: {
            updateLoginState,
            loginState: { verificationEmail },
        },
    } = useStore(SignInStore);

    useEffect(() => {
        return () => {
            const { email } = getValues();
            updateLoginState({ verificationEmail: email });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FormTextField
            name="email"
            label={localize(VerifyEmailLocalizationId.Email)}
            errors={errors}
            control={control}
            defaultValue={verificationEmail}
        />
    );
};
