import React, { useCallback, useMemo } from "react";
import { SubmitHandler } from "react-hook-form";

import { Typography } from "@mui/material";

import { observer } from "mobx-react-lite";
import { Form } from "uiKit/containers/form";
import { Link } from "uiKit/links";

import { LoginStep } from "pages/login/constants/enums";
import { ErrorNotification } from "pages/login/errorNotification";
import { NavigationButtons } from "pages/login/navigationButtons";
import { LoginStepContent } from "pages/login/stepContent";
import { FormEnterEmail } from "pages/login/steps/enterEmail/form";
import { EnterEmailLocalizationId } from "pages/login/steps/enterEmail/localization";
import { EnterEmailFormValues, createEmailSchema } from "pages/login/steps/enterEmail/schema";
import { useEnterEmailStyles } from "pages/login/steps/enterEmail/styles";
import { SignInStore } from "pages/login/store";
import { useLocalize } from "shared/hooks/useLocalize";
import { useRequestContext } from "shared/hooks/useRequest";
import { useStore } from "shared/hooks/useStore";

const EnterEmailElement = (): React.ReactElement => {
    const { classes } = useEnterEmailStyles();

    const localize = useLocalize();

    const { executeRequest } = useRequestContext();

    const {
        store: {
            getLoginOptions,
            setStep,
            clearErrors,
            loginState: { email },
        },
    } = useStore(SignInStore);

    const onSubmit: SubmitHandler<EnterEmailFormValues> = useCallback(
        async data => {
            await executeRequest(getLoginOptions, "", [data.email]);
        },
        [executeRequest, getLoginOptions]
    );

    const defaultValues = {
        email,
    };

    const onLoginWithCredentials = useCallback(() => {
        clearErrors();
        setStep(LoginStep.EnterCredentials);
    }, [clearErrors, setStep]);

    const schema = useMemo(() => createEmailSchema(localize), [localize]);

    const logInHelperText = useMemo(() => {
        return (
            <Typography variant="body2" className={classes.helperText}>
                {localize(EnterEmailLocalizationId.LoginHelperText, {
                    CredentialsLink: (
                        <Link
                            onClick={onLoginWithCredentials}
                            label={localize(EnterEmailLocalizationId.CredentialsLink)}
                            typographyVariant="body2"
                        />
                    ),
                })}
            </Typography>
        );
    }, [localize, onLoginWithCredentials, classes.helperText]);

    return (
        <LoginStepContent>
            <Typography variant="h1">{localize(EnterEmailLocalizationId.LogIn)}</Typography>
            <ErrorNotification />
            <Form<EnterEmailFormValues>
                schema={schema}
                defaultValues={defaultValues}
                onHandleSubmit={onSubmit}
                className={classes.form}
            >
                <FormEnterEmail />
                {logInHelperText}
                <NavigationButtons submitButtonLabel={localize(EnterEmailLocalizationId.ContinueButton)} />
            </Form>
        </LoginStepContent>
    );
};

export const EnterEmail = observer(EnterEmailElement);
