import { useEffect } from "react";

import { TwoColumnLayout } from "layout/twoColumnLayout";
import { observer } from "mobx-react-lite";

import { SuccessfulVerification } from "pages/verifyEmail/successfulVerification";
import { SuccessfulVerificationLocalizationId } from "pages/verifyEmail/successfulVerification/localization";
import { useTokenProcessor } from "pages/verifyEmail/useTokenProcessor";
import { useLocalize } from "shared/hooks/useLocalize";
import { LocalizedComponentName } from "shared/localization/enums";
import { withLocalization } from "shared/localization/withLocalization";

import { FullscreenLoader } from "components/fullscreenLoader";

const EmailVerificationElement = () => {
    const { processToken, isProcessingToken } = useTokenProcessor();

    const localize = useLocalize();

    useEffect(() => {
        processToken();
    }, [processToken]);

    if (isProcessingToken) {
        return <FullscreenLoader />;
    }

    return (
        <TwoColumnLayout
            content={<SuccessfulVerification />}
            imgSrc={localize(SuccessfulVerificationLocalizationId.StepPicture)}
        />
    );
};

export const EmailVerification = withLocalization(
    observer(EmailVerificationElement),
    LocalizedComponentName.SignInEnterEmail
);
