import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";

import { Consent } from "pages/consent";
import { ExpiredEmailVerificationLink } from "pages/errors/expiredLink/expiredEmailVerificationLink";
import { ExpiredLoginLink } from "pages/errors/expiredLink/expiredLoginLink";
import { InternalErrorPage } from "pages/errors/internalError";
import { NotFoundErrorPage } from "pages/errors/notFound";
import { Login } from "pages/login";
import { Logout } from "pages/logout";
import { EmailVerification } from "pages/verifyEmail";
import { AppProviders } from "shared/providers/AppProviders";
import { RootRouteElement } from "shared/routes/RootRouteElement";
import routeNames from "shared/routes/routeNames";

const router = createBrowserRouter([
    {
        element: <RootRouteElement />,
        children: [
            {
                path: routeNames.LOGIN.ROOT,
                element: <Login />,
            },
            {
                path: routeNames.LOGOUT,
                element: <Logout />,
            },
            {
                path: routeNames.CONSENT,
                element: <Consent />,
            },
            {
                path: routeNames.VERIFY.EMAIL,
                element: <EmailVerification />,
            },
            {
                path: routeNames.ERRORS.INTERNAL,
                element: <InternalErrorPage />,
            },
            {
                path: routeNames.ERRORS.NOT_FOUND,
                element: <NotFoundErrorPage />,
            },
            {
                path: routeNames.ERRORS.EXPIRED_LOGIN_LINK,
                element: <ExpiredLoginLink />,
            },
            {
                path: routeNames.ERRORS.EXPIRED_EMAIL_VERIFICATION_LINK,
                element: <ExpiredEmailVerificationLink />,
            },
            {
                path: "/",
                element: <Navigate to={routeNames.LOGIN.ROOT} replace />,
            },
            {
                path: "/*",
                element: <Navigate to="/not-found" replace />,
            },
        ],
    },
]);

function App() {
    return (
        <AppProviders>
            <RouterProvider router={router} />
        </AppProviders>
    );
}

export default App;
