import { makeStyles } from "tss-react/mui";
import { ButtonSize } from "uiKit/buttons/interfaces";

import {
    ApplicationColorPalette,
    Color,
    ShadowsPaletteKey,
    applicationPalettes,
    applicationShadowPalettes,
} from "shared/theme/defaultTheme";

interface ButtonStyle {
    height: string;
    width: string;
    minWidth: string;
    borderRadius: string;
    padding: string;
}

export interface ButtonStylesProps {
    palette: ApplicationColorPalette;
    size?: ButtonSize;
}

const styles: { [key in ButtonSize]: ButtonStyle } = {
    l: { height: "3.5rem", width: "22.5rem", minWidth: "unset", borderRadius: "0.5rem", padding: "1rem 0" },
    m: { height: "3rem", width: "auto", minWidth: "11rem", borderRadius: "0.5rem", padding: "0.75rem 1.5rem" },
    s: { height: "2.5rem", width: "auto", minWidth: "7.5rem", borderRadius: "0.375rem", padding: "0.5rem 1rem" },
};

export function getColorFromPalette(props: ButtonStylesProps, color: keyof Color = 500): string | undefined {
    return applicationPalettes[props.palette][color];
}

export function getShadowFromPalette(props: ButtonStylesProps, shadow: ShadowsPaletteKey): string | undefined {
    return applicationShadowPalettes[props.palette][shadow];
}

export const getButtonSpinnerSize = (size: string): number => {
    if (size === "l") {
        return 32;
    }
    if (size === "m") {
        return 28;
    }
    if (size === "s") {
        return 24;
    }

    return 28;
};

export function getButtonHeight(props: ButtonStylesProps): string {
    return getButtonStyle(props, "height");
}

export function getButtonWidth(props: ButtonStylesProps): string {
    return getButtonStyle(props, "width");
}

export function getButtonMinWidth(props: ButtonStylesProps): string {
    return getButtonStyle(props, "minWidth");
}

export const buttonMaxWidth = "22.5rem";

export function getButtonBorderRadius(props: ButtonStylesProps): string {
    return getButtonStyle(props, "borderRadius");
}

export function getButtonPaddings(props: ButtonStylesProps): string {
    return getButtonStyle(props, "padding");
}

function getButtonStyle(props: ButtonStylesProps, style: keyof ButtonStyle) {
    return styles[props.size || "m"][style];
}

type ButtonTextVariant = "button1" | "button2";

export const buttonSizeToTextVariantMapping: { [key in ButtonSize]: ButtonTextVariant } = {
    l: "button1",
    m: "button1",
    s: "button2",
};

export const useButtonProgressStyles = makeStyles()(() => ({
    root: {
        position: "absolute",
    },
}));
