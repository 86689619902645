import { useCallback } from "react";
import { Controller } from "react-hook-form";
import PinField from "react-pin-field";

import { FormControl, FormHelperText } from "@mui/material";

import { DEFAULT_FIELDS_COUNT } from "uiKit/inputs/confirmationCode/constants";
import { ConfirmationCodeProps, FormConfirmationCodeProps } from "uiKit/inputs/confirmationCode/interfaces";
import { useConfirmationCodeFormControlStyles, useReactCodeInputStyles } from "uiKit/inputs/confirmationCode/styles";
import { FieldError } from "uiKit/inputs/fieldError";
import { shouldShowFormError } from "uiKit/inputs/fieldError/utils";
import { useFormHelperStyles } from "uiKit/inputs/styles";
import { InputLabel } from "uiKit/labels/inputLabel";

export const ConfirmationCode = ({
    fieldsCount = DEFAULT_FIELDS_COUNT,
    showError = false,
    label,
    disabled,
    autoFocus,
    helperText,
    onChange,
}: ConfirmationCodeProps) => {
    const { classes: inputClasses } = useReactCodeInputStyles({ showError });
    const { classes: confirmationCodeFormControlClasses } = useConfirmationCodeFormControlStyles();
    const { classes: helperTextClasses } = useFormHelperStyles();

    const handleOnChange = useCallback(
        (value: string) => {
            if (onChange) {
                onChange(value);
            }
        },
        [onChange]
    );

    return (
        <div className="input-container">
            <InputLabel label={label} disabled={disabled} />
            <FormControl className={confirmationCodeFormControlClasses.root} error={showError}>
                <div className={inputClasses.root}>
                    <PinField
                        className={inputClasses.field}
                        autoComplete="one-time-code"
                        validate={/[0-9]/}
                        placeholder="_"
                        length={fieldsCount}
                        onChange={handleOnChange}
                        onComplete={handleOnChange}
                        autoFocus={autoFocus}
                        inputMode="numeric"
                    />
                </div>
                {showError && <FormHelperText classes={helperTextClasses}>{helperText}</FormHelperText>}
            </FormControl>
        </div>
    );
};

export const FormConfirmationCode = ({ control, errors, ...props }: FormConfirmationCodeProps) => {
    const { defaultValue, name } = props;
    return (
        <Controller
            render={({ field: { onChange } }) => (
                <ConfirmationCode
                    helperText={<FieldError errors={errors} name={name} />}
                    showError={shouldShowFormError({ name, errors })}
                    onChange={onChange}
                    {...props}
                />
            )}
            name={name}
            control={control}
            defaultValue={defaultValue}
        />
    );
};
