import React, { useCallback, useEffect, useMemo } from "react";
import { FieldError, FieldErrors, FieldValues, FormProvider, SubmitHandler, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProps } from "uiKit/containers/form/interfaces";
import { FormLocalizationId } from "uiKit/containers/form/localization";
import { showErrorToast } from "uiKit/containers/toast/utils";
import { InferType } from "yup";

import { useLocalize } from "shared/hooks/useLocalize";

function isFieldError(error: unknown): error is FieldError {
    return typeof error === "object" && error !== null && "message" in error && "type" in error && "ref" in error;
}

function isFieldErrors(error: unknown): error is FieldErrors {
    return typeof error === "object" && error !== null;
}

function flattenErrors<TFieldValues extends FieldValues>(obj: FieldErrors<TFieldValues>): FieldError[] {
    const result: FieldError[] = [];
    Object.values(obj).forEach(it => {
        if (isFieldError(it)) {
            result.push(it);
        }

        if (isFieldErrors(it)) {
            result.push(...flattenErrors(it));
        }
    });

    return result;
}

function isInViewport(element: HTMLElement) {
    if (!element) {
        return false;
    }

    const { x, y } = element.getBoundingClientRect();
    const currentPointerEventsStyle = element.style.pointerEvents;
    element.style.pointerEvents = "auto";
    const isVisible = document.elementFromPoint(x, y) === element;
    element.style.pointerEvents = currentPointerEventsStyle;

    return isVisible;
}

export function Form<TValue extends FieldValues>({
    children,
    schema,
    defaultValues,
    onHandleSubmit,
    className,
    mode,
    shouldUnregister,
    submitOnEnter = false,
}: FormProps<TValue>) {
    const localize = useLocalize();

    type SchemaType = InferType<typeof schema>;
    const methods = useForm<SchemaType>({
        resolver: yupResolver(schema, {}, { raw: true }),
        defaultValues,
        reValidateMode: "onBlur",
        mode: mode || "onSubmit",
        shouldUnregister,
    });

    const { handleSubmit } = methods;
    const onKeyDown = useMemo(() => {
        if (submitOnEnter) {
            return undefined;
        }
        return (event: React.KeyboardEvent) => {
            if (event.key === "Enter" && event.target instanceof HTMLInputElement) {
                event.preventDefault();
            }
        };
    }, [submitOnEnter]);

    const onHandleWrappedSubmit: SubmitHandler<SchemaType> = useCallback(
        (data, e) => onHandleSubmit(data, e, methods),
        [methods, onHandleSubmit]
    );

    useEffect(() => {
        const flatErrors = flattenErrors(methods.formState.errors);
        const filteredErrors = flatErrors
            .map(it => (it.ref ? document.getElementsByName(it.ref.name)[0] : undefined))
            .filter(it => !!it);

        const isAllErrorsNotVisible = filteredErrors.length
            ? filteredErrors.every(it => (it ? !isInViewport(it) : false))
            : false;

        if (isAllErrorsNotVisible) {
            showErrorToast({ title: localize(FormLocalizationId.ThereAreValidationErrors) });
        }
    }, [localize, methods.formState.errors]);

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onHandleWrappedSubmit)} className={className} onKeyDown={onKeyDown}>
                {children}
            </form>
        </FormProvider>
    );
}
