import { ReactNode } from "react";

import { useStepContentStyles } from "pages/login/stepContent/styles";

interface Props {
    children?: ReactNode;
}

export const LoginStepContent = ({ children }: Props) => {
    const { classes } = useStepContentStyles();

    return <div className={classes.root}>{children}</div>;
};
