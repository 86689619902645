import { makeStyles } from "tss-react/mui";

import { TxtColor } from "shared/theme/defaultTheme";

interface Props {
    readonly?: boolean;
}

export const useInputLabelStyles = makeStyles<Props>()((theme, { readonly }) => ({
    root: {
        display: "flex",
        alignItems: "baseline",
        marginBottom: theme.spacing(),
        "& .input-optional-text": {
            color: TxtColor.Txt3,
            paddingLeft: theme.spacing(),
        },
        "&.Mui-disabled": {
            "& .input-label": {
                color: readonly ? TxtColor.Txt1 : TxtColor.Txt6,
            },
            "& .MuiTypography-root": {
                color: readonly ? TxtColor.Txt1 : TxtColor.Txt6,
            },
        },
        "& .input-text": {
            display: "inline-flex",
        },
    },
    focused: {
        color: "inherit !important",
    },
}));
