import React from "react";
import { TypeOptions } from "react-toastify";

import { Check, InfoRounded, PriorityHigh } from "@mui/icons-material";
import { Alert, AlertColor, Typography } from "@mui/material";

import { ToastContentProps } from "uiKit/containers/toast/interfaces";
import { useAlertStyles } from "uiKit/containers/toast/styles";

import { applicationPalettes } from "shared/theme/defaultTheme";

const getAlertSeverityByToastType = (options?: TypeOptions): AlertColor => {
    switch (options) {
        case "info":
            return "info";
        case "warning":
            return "warning";
        case "error":
            return "error";
        case "success":
            return "success";
        default:
            return "info";
    }
};

const getAlertIconByToastType = (options?: TypeOptions): React.ReactNode => {
    switch (options) {
        case "info":
            return <InfoRounded />;
        case "warning":
            return <PriorityHigh />;
        case "error":
            return <PriorityHigh />;
        case "success":
            return <Check />;
        default:
            return "info";
    }
};

const getAlertIconBackgroundByToastType = (options?: TypeOptions): string => {
    switch (options) {
        case "info":
            return applicationPalettes.primary[500];
        case "warning":
            return applicationPalettes.orange[500];
        case "error":
            return applicationPalettes.red[500];
        case "success":
            return applicationPalettes.green[500];
        default:
            return "info";
    }
};

export const ToastContent = ({ title, message, toastProps, closeToast, showCloseIcon }: ToastContentProps) => {
    const hasOnlyMessage = !title;
    const hasOnlyTitle = !message;
    const {
        classes: { titleTypography, messageTypography, ...alertClasses },
    } = useAlertStyles({
        background: getAlertIconBackgroundByToastType(toastProps.type),
        hasOnlyMessage,
        hasOnlyTitle,
        showCloseIcon,
    });

    return (
        <Alert
            severity={getAlertSeverityByToastType(toastProps.type)}
            icon={getAlertIconByToastType(toastProps.type)}
            onClose={showCloseIcon ? closeToast : undefined}
            classes={alertClasses}
        >
            <Typography className={titleTypography} variant="subtitle2">
                {title}
            </Typography>
            {message && (
                <Typography className={messageTypography} variant="body2">
                    {message}
                </Typography>
            )}
        </Alert>
    );
};
