import { ObjectSchema, object } from "yup";

import { VerifyEmailLocalizationId } from "pages/login/steps/verifyEmail/localization";
import { LocalizeFunction } from "shared/hooks/useLocalize";
import { requiredEmailValidator } from "shared/validation/validation.email";

export interface VerifyEmailFormValues {
    email: string;
}

export const createEmailSchema = (localize: LocalizeFunction): ObjectSchema<VerifyEmailFormValues> =>
    object().shape({
        email: requiredEmailValidator(localize, localize(VerifyEmailLocalizationId.Email)),
    });
