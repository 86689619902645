import { Grid, Typography } from "@mui/material";

import { ErrorLayout } from "layout/errorLayout";
import { observer } from "mobx-react-lite";
import { Link } from "uiKit/links";

import { NotFoundLocalizationId } from "pages/errors/notFound/localization";
import { useNotFoundErrorStyles } from "pages/errors/notFound/styles";
import { useLocalize } from "shared/hooks/useLocalize";
import { LocalizedComponentName } from "shared/localization/enums";
import { withLocalizations } from "shared/localization/withLocalization";
import routeNames from "shared/routes/routeNames";

const NotFoundErrorPageElement = () => {
    const { classes } = useNotFoundErrorStyles();
    const localize = useLocalize();

    return (
        <ErrorLayout imgSrc={localize(NotFoundLocalizationId.Image)}>
            <Grid item sm={2} />
            <Grid item xs={12} className={classes.content}>
                <Typography variant="h1" className={classes.title}>
                    {localize(NotFoundLocalizationId.ErrorTitle)}
                </Typography>
                <div className={classes.description}>
                    <Typography variant="body1">
                        {localize(NotFoundLocalizationId.ErrorUnauthorizedDescription)} <br />
                        <Typography variant="body1">
                            <Link
                                link={routeNames.LOGIN.ROOT}
                                label={localize(NotFoundLocalizationId.LoginLink)}
                                typographyVariant="body1"
                            />
                            {` ${localize(NotFoundLocalizationId.OrText)} `}
                            <Link
                                link={routeNames.PORTAL.SIGN_UP}
                                label={localize(NotFoundLocalizationId.SignupLink)}
                                typographyVariant="body1"
                            />
                        </Typography>
                    </Typography>
                </div>
            </Grid>
        </ErrorLayout>
    );
};

export const NotFoundErrorPage = withLocalizations(observer(NotFoundErrorPageElement), [
    LocalizedComponentName.NotFoundErrorPage,
]);
