import React from "react";

import { usePublicLayoutStyles } from "layout/publicLayout/styles";

interface PublicLayoutProps {
    children: React.ReactNode;
}

export const PublicLayout = ({ children }: PublicLayoutProps) => {
    const { classes } = usePublicLayoutStyles();

    return (
        <div className={classes.root}>
            <div className={classes.content}>{children}</div>
        </div>
    );
};
