import { makeStyles } from "tss-react/mui";

import { TxtColor, mediumBreakpoint, tabletBreakpoint } from "shared/theme/defaultTheme";

export const useCheckInboxStyles = makeStyles()(theme => ({
    backButton: {
        marginTop: theme.spacing(8),
        [theme.breakpoints.down(tabletBreakpoint)]: {
            width: "100%",
        },
    },
    disclaimer: {
        textAlign: "center",
        marginTop: theme.spacing(4),
    },
    magicLinkText: {
        color: TxtColor.Txt2,
        wordBreak: "break-word",
    },
    hint: {
        textAlign: "center",
        margin: theme.spacing(3, 0, 2, 0),
        [theme.breakpoints.up(mediumBreakpoint)]: {
            margin: theme.spacing(3, 0, 6, 0),
        },
    },
}));
