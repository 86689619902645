import { TypographyVariant } from "@mui/material";

import { AvatarSize } from "uiKit/containers/avatar/interfaces";

export const getAvatarDimensionsBySize = (size: string): AvatarSize => {
    if (size === "l") {
        return { width: "160px", height: "160px" };
    }
    if (size === "xl") {
        return { width: "80px", height: "80px" };
    }
    if (size === "s") {
        return { width: "64px", height: "64px" };
    }
    if (size === "xs") {
        return { width: "40px", height: "40px" };
    }
    if (size === "xxs") {
        return { width: "24px", height: "24px" };
    }
    return { width: "64px", height: "64px" };
};

export const getAvatarTypographyBySize = (size: string): TypographyVariant => {
    switch (size) {
        case "l":
            return "h1";
        case "s":
            return "h4";
        case "xxs":
            return "h6";
        default:
            return "h4";
    }
};

export const formatUserNameForAvatar = (firstName?: string, lastName?: string): string => {
    if (!firstName && !lastName) {
        return "";
    }

    let abbreviation = "";
    if (firstName) {
        abbreviation = `${firstName[0]}`;
    }

    if (lastName) {
        abbreviation = `${abbreviation}${lastName[0]}`;
    }

    return abbreviation.toUpperCase();
};
