import { makeStyles } from "tss-react/mui";
import { getMainScrollbarStyles } from "uiKit/styles";

import { appAdditionalShadow, mediumBreakpoint, mobileBreakpoint, whiteColor } from "shared/theme/defaultTheme";

export const useTwoColumnLayoutStyles = makeStyles()(theme => ({
    backgroundImage: {
        width: "40%",
        background: "linear-gradient(230.72deg, #F5F8FF 35.62%, #FFFFFF 77.5%)",
        overflow: "hidden",
        "& img": {
            width: "100%",
            height: "100%",
            objectFit: "cover",
        },
    },
    content: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flexWrap: "nowrap",
        borderRadius: "24px 0 0 24px",
        width: `calc(60% + ${theme.spacing(6)})`,
        boxShadow: appAdditionalShadow.bg2["24"],
        backgroundColor: whiteColor,
        marginLeft: theme.spacing(-6),
        padding: theme.spacing(0, 2),
        [theme.breakpoints.down(mediumBreakpoint)]: {
            width: "100%",
            marginLeft: "0",
        },
        [theme.breakpoints.up(mediumBreakpoint)]: {
            padding: 0,
        },
        ...getMainScrollbarStyles(theme),
    },
    footerContainer: {
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down(mobileBreakpoint)]: {
            marginBottom: theme.spacing(2),
        },
    },
}));
