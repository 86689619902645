import { makeStyles } from "tss-react/mui";

import { tabletBreakpoint } from "shared/theme/defaultTheme";

export const useStepContentStyles = makeStyles()(theme => ({
    root: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        marginTop: theme.spacing(29),
        marginBottom: theme.spacing(20),
        [theme.breakpoints.down(tabletBreakpoint)]: {
            marginTop: theme.spacing(16),
        },
    },
}));
