import React from "react";

import { CircularProgress } from "@mui/material";

import useFullscreenLoaderStyles from "components/fullscreenLoader/styles";

interface FullscreenLoaderProps {
    inner?: boolean;
}

export const FullscreenLoader: React.FC<FullscreenLoaderProps> = ({ inner = false }: FullscreenLoaderProps) => {
    const { classes } = useFullscreenLoaderStyles({ inner });

    return (
        <div className={classes.root} data-testid="fullscreen-loader">
            <CircularProgress />
        </div>
    );
};
