import { StringSchema, string } from "yup";

import { LocalizeFunction } from "shared/hooks/useLocalize";
import { ValidationLocalizationId } from "shared/validation/validation.localization";
import { getValidatorValues } from "shared/validation/validation.valuesGetters";

export const smsCodeValidator = (localize: LocalizeFunction, propertyName: string): StringSchema<string> => {
    return string()
        .required(localize(ValidationLocalizationId.NotEmptyValidatorKey, getValidatorValues(propertyName)))
        .test(
            "4digits",
            localize(ValidationLocalizationId.RegularExpressionValidator, getValidatorValues(propertyName)),
            value => {
                const parsedValue = Number(value);
                return parsedValue >= 1000 && parsedValue <= 9999;
            }
        );
};
