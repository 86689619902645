export enum LoginStep {
    EnterEmail,
    ChooseAuthenticationMethod,
    CheckEmailInbox,
    EnterPhoneCode,
    ProcessEmailToken,
    EnterCredentials,
    VerifyEmail,
    CheckVerificationEmailInbox,
    ChooseAccount,
}

export enum AuthMethod {
    Email = 1,
    Sms = 2,
}
