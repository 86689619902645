import { Theme } from "@mui/material";

import { ElementPaddingControl, ElementWidthControl } from "uiKit/interfaces";

export const getTextFieldWidthsBySize = (size: string): ElementWidthControl => {
    if (size === "auto") {
        return { width: "100%", maxWidth: "360px", minWidth: "168px" };
    }
    if (size === "small") {
        return { width: "168px", maxWidth: "168px", minWidth: "168px" };
    }
    if (size === "extra-small") {
        return { width: "100%", maxWidth: "168px", minWidth: "98px" };
    }
    if (size === "tiny") {
        return { width: "100%", maxWidth: "auto", minWidth: "auto" };
    }
    if (size === "full") {
        return { width: "100%", maxWidth: "auto", minWidth: "auto" };
    }
    return { width: "360px", maxWidth: "360px", minWidth: "360px" };
};

export const getTextFieldPaddingBySize = (theme: Theme, size: string): ElementPaddingControl => {
    if (size === "extra-small") {
        return { padding: theme.spacing(1, 1.25), height: "16px" };
    }
    if (size === "tiny") {
        return { padding: theme.spacing(0.5, 1), height: "24px" };
    }
    return { padding: theme.spacing(1, 2), height: "32px" };
};
