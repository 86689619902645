import { makeStyles } from "tss-react/mui";

import background from "assets/images/errors-background.svg";
import { tabletBreakpoint } from "shared/theme/defaultTheme";

export const useErrorStyles = makeStyles()(theme => ({
    root: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",

        "&::after": {
            content: "''",
            background: `url(${background}) no-repeat center top`,
            opacity: 0.6,
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            zIndex: -1,
        },
    },

    backgroundImage: {
        maxWidth: "100%",
        "& img": {
            width: "100%",
            height: "100%",
            objectFit: "contain",
        },
    },

    body: {
        textAlign: "center",
        [theme.breakpoints.down(tabletBreakpoint)]: {
            paddingRight: theme.spacing(1),
            paddingLeft: theme.spacing(1),
        },
    },

    stepBack: {
        width: "100%",
    },

    footerContainer: {
        marginTop: "auto",
        marginBottom: theme.spacing(4),
    },
}));
