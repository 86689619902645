import { useEffect } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";

import { observer } from "mobx-react-lite";

import { SignOutStore } from "pages/logout/store";
import { useStore } from "shared/hooks/useStore";
import routeNames from "shared/routes/routeNames";
import { useRootStore } from "shared/store/useRootStore";

import { FullscreenLoader } from "components/fullscreenLoader";

const LogoutElement = () => {
    const [search] = useSearchParams();
    const logoutId = search.get("logoutId");

    const navigate = useNavigate();

    const {
        rootStore,
        rootStore: { isAuthenticated },
    } = useRootStore();

    const {
        store: { logout, logoutDetails },
    } = useStore(SignOutStore, () => new SignOutStore(rootStore));

    useEffect(() => {
        logout(logoutId || undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!logoutId) {
        return <Navigate to={routeNames.ERRORS.NOT_FOUND} />;
    }

    if (!isAuthenticated && !logoutDetails) {
        return <Navigate to={routeNames.LOGIN.ROOT} replace />;
    }

    if (!logoutDetails) {
        return <FullscreenLoader />;
    }

    if (!logoutDetails.iframeUrl) {
        if (logoutDetails.postLogoutRedirectUri) {
            window.location.replace(logoutDetails.postLogoutRedirectUri);
        } else {
            navigate(routeNames.LOGIN.ROOT, { replace: true });
        }
    }

    return (
        <>
            <FullscreenLoader />;
            {logoutDetails.iframeUrl && (
                <iframe
                    width="0"
                    height="0"
                    src={logoutDetails.iframeUrl}
                    onLoad={() => {
                        navigate(routeNames.LOGIN.ROOT, { replace: true });
                    }}
                />
            )}
        </>
    );
};

export const Logout = observer(LogoutElement);
