import { FieldPath, FieldValues, UseFormReturn } from "react-hook-form";

import axios from "axios";
import { hasIn } from "lodash";
import { showErrorToast } from "uiKit/containers/toast/utils";

import apiConstants from "shared/api/constants";
import { AxiosBadRequestError, BadRequestError } from "shared/api/errors";

function jsonDataReviver(_key: string, value: unknown): Date | unknown {
    if (typeof value === "string" && isSerializedDate(value)) {
        return new Date(value);
    }

    if (value === null) {
        return undefined;
    }

    return value;
}

function isSerializedDate(value: string) {
    // Dates are serialized in TZ format, example: '1981-12-20T04:00:14.000Z'.
    const datePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{0,7})?Z?$/;
    return datePattern.test(value);
}

export function transformResponse<T = unknown>(data: unknown): T {
    if (typeof data === "string") {
        try {
            return JSON.parse(data, jsonDataReviver);
        } catch (e) {
            /* Ignore */
        }
    }

    return data as T;
}

export function isAxios400Error(error: unknown): error is AxiosBadRequestError<BadRequestError> {
    if (!axios.isAxiosError(error)) {
        return false;
    }

    return error.response?.status === apiConstants.STATUS_CODES.BAD_REQUEST && !!error.response?.data.errors;
}

export function handleValidationErrors<TFieldValues extends FieldValues>(
    error: unknown,
    formFields?: TFieldValues,
    methods?: UseFormReturn<TFieldValues>,
    formFieldsPrefix = ""
): void {
    if (!isAxios400Error(error)) {
        return;
    }

    const validationErrors = error.response.data.errors;
    const getFieldName = (fieldName: string) => {
        if (hasIn(formFields, fieldName)) {
            return fieldName as FieldPath<TFieldValues>;
        }
        const prefixedFieldName = `${formFieldsPrefix}${fieldName}`;
        if (!!formFieldsPrefix && hasIn(formFields, prefixedFieldName)) {
            return prefixedFieldName as FieldPath<TFieldValues>;
        }
        return undefined;
    };

    const fieldsErrors = validationErrors.filter(({ fieldName }) => !!getFieldName(fieldName));
    const otherErrors = validationErrors.filter(({ fieldName }) => !getFieldName(fieldName));

    if (methods) {
        fieldsErrors.forEach(({ message, fieldName }) => {
            const formFieldName = getFieldName(fieldName);
            if (formFieldName) {
                methods.setError(formFieldName, { type: "manual", message });
            }
        });
    }

    otherErrors.forEach(({ message }) => showErrorToast({ message }));
}
