/**
 * Summary: Verify hybris user change email request.
 *
 * Tags: Ui
 */
import { AxiosRequestConfig } from "axios";

import { httpPut } from "shared/api/apiHandler";

export const API_PUT_UI_EMAIL_VERIFY_URL = "api/v1/ui/email/verify";

export const apiPutUiEmailVerify = async (
    request?: ApiPutUiEmailVerifyRequest,
    config?: AxiosRequestConfig
): Promise<ApiPutUiEmailVerifyResponse> => {
    const result = await httpPut<ApiPutUiEmailVerifyResponse, ApiPutUiEmailVerifyRequest>(
        "",
        API_PUT_UI_EMAIL_VERIFY_URL,
        request,
        config
    );
    return result.data;
};

/**
 *
 */
export type ApiPutUiEmailVerifyRequest = VerifyUserEmailRequest;

/**
 * Description: Success
 */
export type ApiPutUiEmailVerifyResponse = VerifyUserEmailResponse;

interface VerifyUserEmailRequest {
    token?: string;
}

interface VerifyUserEmailResponse {
    email: string;
    authToken?: string;
}
