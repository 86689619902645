import { NavigateFunction } from "react-router/dist/lib/hooks";

import { AxiosError, AxiosPromise } from "axios";
import { showErrorToast } from "uiKit/containers/toast/utils";

import apiConstants from "shared/api/constants";
import { LocalizationId } from "shared/api/interceptors/localization";
import { LocalizeFunction } from "shared/hooks/useLocalize";
import routeNames from "shared/routes/routeNames";

export const handleErrorApiResponse = (
    error: AxiosError,
    localize: LocalizeFunction,
    navigate: NavigateFunction
): AxiosPromise => {
    if (!error.isAxiosError) {
        return Promise.reject(error);
    }

    if (!error.response) {
        showErrorToast({
            title: localize(LocalizationId.InternalServerErrorToastTitle),
            message: localize(LocalizationId.InternalServerErrorToastBody),
        });
        return Promise.reject(error);
    }

    if (error.response.status === apiConstants.STATUS_CODES.NOT_FOUND) {
        navigate(routeNames.ERRORS.NOT_FOUND);
    }

    if (
        error.response.status === apiConstants.STATUS_CODES.INTERNAL_ERROR ||
        error.response.status === apiConstants.STATUS_CODES.SERVICE_UNAVAILABLE
    ) {
        showErrorToast({
            title: localize(LocalizationId.InternalServerErrorToastTitle),
            message: localize(LocalizationId.InternalServerErrorToastBody),
        });
    }

    return Promise.reject(error);
};
