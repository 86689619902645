import { makeStyles } from "tss-react/mui";

import { tabletBreakpoint } from "shared/theme/defaultTheme";

export const useNavigationButtonsStyles = makeStyles()(theme => ({
    buttonContainer: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(3),
        marginTop: theme.spacing(5),
        [theme.breakpoints.down(tabletBreakpoint)]: {
            width: "100%",
        },
    },
}));
