import { makeStyles } from "tss-react/mui";

import { tabletBreakpoint } from "shared/theme/defaultTheme";

export const useInternalErrorStyles = makeStyles()(theme => ({
    content: {
        maxWidth: "100%",
    },

    stepBack: {
        width: "100%",
    },

    marginTopOneSpacing: {
        marginTop: theme.spacing(1),
    },

    marginVerticalTwoSpacing: {
        marginTop: theme.spacing(2),

        [theme.breakpoints.down(tabletBreakpoint)]: {
            marginBottom: theme.spacing(2),
        },
    },
}));
