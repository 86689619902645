import React from "react";

import { Footer } from "layout/footer";
import { useTwoColumnLayoutStyles } from "layout/twoColumnLayout/styles";

interface TwoColumnLayoutProps {
    imgSrc?: string;
    content: React.ReactNode;
    header?: React.ReactNode;
}

export const TwoColumnLayout = ({ imgSrc, content, header }: TwoColumnLayoutProps) => {
    const { classes } = useTwoColumnLayoutStyles();

    return (
        <>
            <div className={`left-panel ${classes.backgroundImage}`}>
                <img src={imgSrc} alt="" />
            </div>
            <div className={`right-panel ${classes.content}`}>
                {header}
                {content}
                <div className={classes.footerContainer}>
                    <Footer />
                </div>
            </div>
        </>
    );
};
