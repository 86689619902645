import { useCallback, useMemo } from "react";

import { Typography } from "@mui/material";

import { observer } from "mobx-react-lite";
import { Form } from "uiKit/containers/form";
import { SubmitHandler } from "uiKit/containers/form/interfaces";

import { ErrorNotification } from "pages/login/errorNotification";
import { NavigationButtons } from "pages/login/navigationButtons";
import { LoginStepContent } from "pages/login/stepContent";
import { useEnterEmailStyles } from "pages/login/steps/enterEmail/styles";
import { FormEnterEmail } from "pages/login/steps/verifyEmail/form";
import { VerifyEmailLocalizationId } from "pages/login/steps/verifyEmail/localization";
import { VerifyEmailFormValues, createEmailSchema } from "pages/login/steps/verifyEmail/schema";
import { SignInStore } from "pages/login/store";
import { useLocalize } from "shared/hooks/useLocalize";
import { useRequestContext } from "shared/hooks/useRequest";
import { useStore } from "shared/hooks/useStore";

const VerifyEmailElement = () => {
    const { classes } = useEnterEmailStyles();

    const localize = useLocalize();

    const { executeRequest } = useRequestContext();

    const {
        store: {
            verifyEmail,
            loginState: { verificationEmail },
        },
    } = useStore(SignInStore);

    const defaultValues = {
        email: verificationEmail,
    };

    const onSubmit: SubmitHandler<VerifyEmailFormValues> = useCallback(
        async ({ email }) => {
            await executeRequest(verifyEmail, "", [email]);
        },
        [executeRequest, verifyEmail]
    );

    const schema = useMemo(() => createEmailSchema(localize), [localize]);

    return (
        <LoginStepContent>
            <Typography variant="h1">{localize(VerifyEmailLocalizationId.VerifyEmailTitle)}</Typography>
            <ErrorNotification />
            <Form schema={schema} defaultValues={defaultValues} onHandleSubmit={onSubmit} className={classes.form}>
                <FormEnterEmail />
                <NavigationButtons
                    submitButtonLabel={localize(VerifyEmailLocalizationId.ContinueButton)}
                    backButtonLabel={localize(VerifyEmailLocalizationId.BackButton)}
                />
            </Form>
        </LoginStepContent>
    );
};
export const VerifyEmail = observer(VerifyEmailElement);
