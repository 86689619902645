import { makeStyles } from "tss-react/mui";

import { TxtColor, mobileBreakpoint, tabletBreakpoint } from "shared/theme/defaultTheme";

interface PhoneCodeStylesProps {
    isDisabled: boolean;
}

export const usePhoneCodeStyles = makeStyles<PhoneCodeStylesProps>()((theme, { isDisabled }) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        alignItems: "center",
        [theme.breakpoints.down(tabletBreakpoint)]: {
            width: "100%",
        },
    },
    resendCodeContainer: {
        marginTop: theme.spacing(5),
        [theme.breakpoints.down(mobileBreakpoint)]: {
            marginTop: theme.spacing(2),
        },
    },
    resendCodeTypography: {
        color: TxtColor.Txt2,
        textAlign: "center",
    },
    phoneVerificationDescription: {
        color: TxtColor.Txt2,
        marginTop: theme.spacing(4),
        width: "360px",
        textAlign: "center",
        [theme.breakpoints.down(tabletBreakpoint)]: {
            width: "100%",
        },
    },
    phoneCodeContainer: {
        marginTop: theme.spacing(4),
    },
    link: {
        color: isDisabled ? TxtColor.Txt5 : "inherit",
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        alignItems: "center",
    },
}));
