import React, { useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";

import { Link as MaterialLink, Typography, TypographyVariant } from "@mui/material";

import clsx from "clsx";
import { useLinkStyles } from "uiKit/links/styles";

type LinkVariant = "primary" | "secondary";
type LinkTypography = Exclude<TypographyVariant, "button">;
type TypographyVariantsValue = "link1" | "link1subtitle" | "link2" | "link3";
type TypographyVariants = {
    [variantKey in LinkVariant]: {
        [typographyKey in LinkTypography]: TypographyVariantsValue;
    };
};

const typographyVariants: TypographyVariants = {
    primary: {
        body1: "link1",
        body2: "link1",
        subtitle1: "link1subtitle",
        subtitle2: "link1subtitle",
        caption: "link2",
        overline: "link2",
        h1: "link3",
        h2: "link3",
        h3: "link3",
        h4: "link3",
        h5: "link3",
        h6: "link3",
    },
    secondary: {
        body1: "link1",
        body2: "link1",
        subtitle1: "link1",
        subtitle2: "link1",
        caption: "link1",
        overline: "link1",
        h1: "link1",
        h2: "link1",
        h3: "link1",
        h4: "link1",
        h5: "link1",
        h6: "link1",
    },
};
function getLinkVariant(typographyVariant: LinkTypography, linkVariant: LinkVariant): string {
    return typographyVariants[linkVariant][typographyVariant];
}

interface LinkPropTypes {
    label: React.ReactNode;
    tabIndex?: number;
    link?: string;
    onClick?: (() => void) | null;
    typographyVariant: LinkTypography;
    isOpenAtNewPage?: boolean;
    disabled?: boolean;
    download?: boolean;
    noWrap?: boolean;
    className?: string;
    wrapperClassName?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reference?: React.Ref<any>;
    variant?: LinkVariant;
    overflowHidden?: boolean;
    spaceAfter?: boolean;
}

/**
 * Link component
 * @param {string} label - A text content of the link
 * @param {function=} onClick - click handler (without "link" parameter underline will be close to text, with "link" style will be same as just "link" without "onClick")
 * @param {string=} link - path which is going to be provided to link.
 * @param {string} typographyVariant - Value which changes styling for component due to typography palette and is responsible for link type changing.
 * @param {isOpenAtNewPage} boolean - Value which is open link at new page if true.
 */
export const Link = ({
    label,
    link,
    typographyVariant,
    onClick = null,
    disabled = false,
    isOpenAtNewPage,
    tabIndex = 0,
    download,
    noWrap = false,
    reference,
    className,
    wrapperClassName,
    variant = "primary",
    overflowHidden = false,
    spaceAfter = false,
}: LinkPropTypes) => {
    const { classes } = useLinkStyles();

    const isOutside =
        link && (link.indexOf("http") !== -1 || link.indexOf("tel") !== -1 || link.indexOf("mailto") !== -1);

    const content = useMemo(() => {
        if (!disabled) {
            if (onClick && link) {
                return (
                    <RouterLink
                        className={clsx([classes[variant], getLinkVariant(typographyVariant, variant), className])}
                        onClick={onClick}
                        tabIndex={tabIndex}
                        target={isOpenAtNewPage ? "_blank" : "_self"}
                        to={link}
                        ref={reference}
                    >
                        {label}
                        {spaceAfter && <>&nbsp;</>}
                    </RouterLink>
                );
            }

            if (onClick) {
                return (
                    <MaterialLink
                        type="button"
                        className={clsx([classes[variant], className, "link-button"])}
                        variant={typographyVariant}
                        onClick={onClick}
                        tabIndex={tabIndex}
                        target={isOpenAtNewPage ? "_blank" : "_self"}
                        noWrap={noWrap}
                        ref={reference}
                    >
                        {label}
                        {spaceAfter && <>&nbsp;</>}
                    </MaterialLink>
                );
            }

            if (isOutside) {
                return (
                    <MaterialLink
                        className={clsx([classes[variant], getLinkVariant(typographyVariant, variant), className])}
                        variant={typographyVariant}
                        href={link}
                        tabIndex={tabIndex}
                        target={isOpenAtNewPage ? "_blank" : "_self"}
                        download={download}
                        noWrap={noWrap}
                        underline="none"
                    >
                        {label}
                        {spaceAfter && <>&nbsp;</>}
                    </MaterialLink>
                );
            }

            if (link) {
                return (
                    <RouterLink
                        className={clsx([classes[variant], getLinkVariant(typographyVariant, variant), className])}
                        to={link}
                        target={isOpenAtNewPage ? "_blank" : "_self"}
                    >
                        {label}
                        {spaceAfter && <>&nbsp;</>}
                    </RouterLink>
                );
            }
        }

        return <span className={className}>{label}</span>;
    }, [
        disabled,
        className,
        reference,
        label,
        onClick,
        isOutside,
        link,
        classes,
        variant,
        typographyVariant,
        tabIndex,
        isOpenAtNewPage,
        noWrap,
        spaceAfter,
        download,
    ]);

    return (
        <Typography
            variant={typographyVariant}
            component="span"
            noWrap={noWrap}
            className={clsx(classes.wrapper, wrapperClassName, overflowHidden && classes.wrapperOverflowHidden)}
            ref={onClick ? null : reference}
        >
            {content}
        </Typography>
    );
};
