import { LocalizationMessages } from "shared/api/staticCms/models/localizationMessages";
import { LocalizationVersion } from "shared/api/staticCms/models/localizationVersion";
import { LocalizedComponentName } from "shared/localization/enums";
import { isEmpty } from "shared/utils/object.utils";

export class ComponentLocalization {
    readonly name: LocalizedComponentName;
    readonly messages: LocalizationMessages;

    constructor(componentName: LocalizedComponentName, messages: LocalizationMessages) {
        this.name = componentName;
        this.messages = messages;
    }
}

export class LocalizationCache {
    public readonly components: Record<LocalizedComponentName, ComponentLocalization>;
    public readonly version?: LocalizationVersion;

    constructor(components: Record<string, ComponentLocalization>, version?: LocalizationVersion) {
        this.components = components;
        this.version = version;
        if (this.version && version?.updatedAt) {
            this.version.updatedAt = new Date(version.updatedAt);
        }
    }

    addOrUpdateComponentLocalization(componentName: LocalizedComponentName, localization: ComponentLocalization): void {
        this.components[componentName] = localization;
    }

    getComponentLocalization(componentName: LocalizedComponentName): ComponentLocalization | undefined {
        return this.components[componentName];
    }

    getAllMessages(): LocalizationMessages {
        const allMessages: LocalizationMessages = {};
        Object.values(this.components).forEach(component => {
            if (isEmpty(component.messages)) {
                return;
            }

            Object.keys(component.messages).forEach(key => {
                allMessages[`${component.name}.${key}`] = component.messages[key];
            });
        });
        return allMessages;
    }
}

export function saveLocalizationCache(locale: string, localizationCache: LocalizationCache): void {
    const serializedCache = JSON.stringify(localizationCache);
    window.localStorage.setItem(createKey(locale), serializedCache);
}

export function getLocalizationCache(locale: string): LocalizationCache {
    const serializedCache = window.localStorage.getItem(createKey(locale));
    const parsedCache: {
        components?: Record<string, ComponentLocalization>;
        version?: LocalizationVersion;
    } = serializedCache ? JSON.parse(serializedCache) : {};
    return new LocalizationCache(parsedCache.components || {}, parsedCache.version);
}

const createKey = (locale: string): string => `${locale}_messages`;
