import { Theme } from "@mui/material";

import { CSSObject } from "tss-react";

import { TxtColor, tabletBreakpoint } from "shared/theme/defaultTheme";

export const scrollStyles: CSSObject = {
    scrollbarWidth: "thin",
    overflowY: "auto",
    "@supports (overflow-y: overlay)": {
        "overflow-y": "overlay",
    },
    "&::-webkit-scrollbar": {
        width: "12px",
    },
    "&::-webkit-scrollbar-thumb": {
        border: "4px solid transparent",
        backgroundClip: "padding-box",
        borderRadius: "8px",
        backgroundColor: TxtColor.Txt6,
    },
};

export const getMainScrollbarStyles = (theme: Theme): CSSObject => {
    return {
        scrollBehavior: "smooth",
        overflowY: "auto",
        "@supports (overflow-y: overlay)": {
            "overflow-y": "overlay",
        },
        "&::-webkit-scrollbar": {
            [theme.breakpoints.down(tabletBreakpoint)]: {
                width: "12px",
            },
            width: "22px",
        },
        "&::-webkit-scrollbar-thumb": {
            border: "8px solid transparent",
            backgroundClip: "padding-box",
            borderRadius: "16px",
            backgroundColor: TxtColor.Txt6,
            [theme.breakpoints.down(tabletBreakpoint)]: {
                border: "4px solid transparent",
                borderRadius: "8px",
            },
        },
    };
};
