import { Context, createContext, useCallback, useContext, useEffect, useMemo } from "react";

interface StoreType {}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type StoreClassType<T> = abstract new (...args: any[]) => T;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CreatableStoreClassType<T> = new (...args: any[]) => T;

const contexts: Map<StoreClassType<unknown>, Context<unknown>> = new Map();
const contextUsages: Map<StoreClassType<unknown>, number> = new Map();

export const useStore = <T extends StoreType>(
    StoreClass: StoreClassType<T>,
    storeFactory?: () => T
): { store: T; context: Context<T> } => {
    const create = useCallback(
        () => createContext<unknown>(storeFactory ? storeFactory() : new (StoreClass as CreatableStoreClassType<T>)()),
        [StoreClass, storeFactory]
    );

    const context = useMemo(() => (contexts.get(StoreClass) || create()) as Context<T>, [StoreClass, create]);
    const store = useContext<T>(context);

    if (!contexts.has(StoreClass)) {
        contexts.set(StoreClass, context as Context<unknown>);
    }

    useEffect(() => {
        const currentContextUsageCount = contextUsages.get(StoreClass) || 0;
        contextUsages.set(StoreClass, currentContextUsageCount + 1);
    }, [StoreClass]);

    useEffect(
        () => () => {
            const currentContextUsageCount = contextUsages.get(StoreClass) || 0;
            const newContextUsageCount = currentContextUsageCount - 1;
            contextUsages.set(StoreClass, newContextUsageCount);

            if (newContextUsageCount <= 0) {
                contexts.delete(StoreClass);
                contextUsages.delete(StoreClass);
            }
        },
        [StoreClass]
    );

    return {
        store,
        context,
    };
};
