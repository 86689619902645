import { useCallback, useMemo, useState } from "react";

import { Typography } from "@mui/material";

import { countBy, filter } from "lodash";
import { observer } from "mobx-react-lite";
import { SolidButton } from "uiKit/buttons/solidButton";

import { AccountType } from "pages/login/interfaces";
import { LoginStepContent } from "pages/login/stepContent";
import { AccountView } from "pages/login/steps/chooseAccount/accountView";
import { ChooseAccountLocalizationId } from "pages/login/steps/chooseAccount/localization";
import { useChooseAccountStyles } from "pages/login/steps/chooseAccount/styles";
import { SignInStore } from "pages/login/store";
import { LOGIN_ACTION } from "shared/constants/actions";
import { useLocalize } from "shared/hooks/useLocalize";
import { useRequestContext } from "shared/hooks/useRequest";
import { useStore } from "shared/hooks/useStore";

const ChooseAccountElement = () => {
    const localize = useLocalize();
    const { classes } = useChooseAccountStyles();
    const { executeRequest, isLoadingAction } = useRequestContext();
    const [selectedAccountId, setSelectedAccountId] = useState(0);

    const {
        store: {
            continueLogin,
            loginState: { accounts, firstName, lastName },
        },
    } = useStore(SignInStore);

    const onContinueButtonClick = useCallback(() => {
        executeRequest(continueLogin, LOGIN_ACTION, [selectedAccountId]);
    }, [continueLogin, executeRequest, selectedAccountId]);

    const sortedAccounts = useMemo(() => {
        const counts = countBy(accounts, it => it.accountType.id);
        const duplicatedAccountTypes = new Set<number>(
            filter(
                accounts.map(it => it.accountType.id),
                it => counts[it] > 1
            )
        );

        return accounts.map(it => {
            const isCompanyUser =
                it.accountType.id == AccountType.CorporateLandlord || it.accountType.id == AccountType.Agent;

            const primaryName = isCompanyUser ? it.accountName : firstName;
            const secondaryName = isCompanyUser ? undefined : lastName;
            const accountType = duplicatedAccountTypes.has(it.accountType.id)
                ? `${localize(it.accountType.text)} ${String(it.accountIndex).padStart(2, "0")}`
                : localize(it.accountType.text);

            return (
                <div onClick={() => setSelectedAccountId(it.accountId)}>
                    <AccountView
                        key={it.accountId}
                        primaryName={primaryName}
                        secondaryName={secondaryName}
                        accountType={accountType}
                        photoUrl={it.photo?.downloadUrl}
                        isSelected={selectedAccountId == it.accountId}
                    />
                </div>
            );
        });
    }, [accounts, firstName, lastName, localize, selectedAccountId]);

    return (
        <LoginStepContent>
            <Typography variant="h1" className={classes.title}>
                {localize(ChooseAccountLocalizationId.StepTitle)}
            </Typography>
            <div className={classes.accounts}>{sortedAccounts}</div>
            <SolidButton
                label={localize(ChooseAccountLocalizationId.ContinueButton)}
                size="l"
                palette="primary"
                onClick={onContinueButtonClick}
                disabled={!selectedAccountId}
                isLoading={isLoadingAction(LOGIN_ACTION)}
            />
        </LoginStepContent>
    );
};

export const ChooseAccount = observer(ChooseAccountElement);
