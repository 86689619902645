import { AuthMethod, LoginStep } from "pages/login/constants/enums";
import { ApiPostUiLoginResponse } from "shared/api/auth/ui/apiPostUiLogin";

type AccountDetails = Unpacked<ApiPostUiLoginResponse["accounts"]>;

export interface LoginState {
    email: string;
    verificationEmail: string;
    userName: string;
    password: string;
    firstName: string;
    lastName: string;
    phone: string;
    authCode: string;
    authToken?: string;
    activeStep: LoginStep;
    selectedAuthMethod: AuthMethod;
    accounts: AccountDetails[];
}

export enum AccountType {
    Admin = 1,
    PrivateLandlord = 2,
    CorporateLandlord = 3,
    Tenant = 4,
    Agent = 5,
    None = 100,
}
