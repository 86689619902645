import React, { useCallback } from "react";
import { Controller } from "react-hook-form";

import { FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup, Typography } from "@mui/material";

import clsx from "clsx";
import { FieldError } from "uiKit/inputs/fieldError";
import { shouldShowFormError } from "uiKit/inputs/fieldError/utils";
import { FormRadioControlProps, RadioControlProps, RadioDataProps } from "uiKit/inputs/radioControl/interfaces";
import {
    useFormControlLabelStyles,
    useFormControlStyles,
    useRadioContainerStyles,
    useRadioStyles,
} from "uiKit/inputs/radioControl/radioControl.styles";
import { useAdditionalTextStyles, useFormHelperStyles } from "uiKit/inputs/styles";

export const RadioControl = ({
    data,
    value,
    onChange,
    onBlur,
    helperText,
    groupLabel,
    name,
    showError,
    className,
    radioContainerClassName,
    inline = false,
    typography = "body2",
    additionalTextTypography = "body2",
}: RadioControlProps) => {
    const { classes } = useFormControlStyles();
    const { classes: additionalTextClasses } = useAdditionalTextStyles();
    const { classes: helperTextClasses } = useFormHelperStyles();
    const { classes: radioContainerStyles } = useRadioContainerStyles({ inline });
    const { classes: radioClasses } = useRadioStyles();
    const { classes: formControlClasses } = useFormControlLabelStyles();

    const renderAdditional = useCallback(
        (item: RadioDataProps) => {
            if (item.additionalComponent) {
                return <div className={additionalTextClasses.root}>{item.additionalComponent}</div>;
            }

            if (!item.additionalText) {
                return null;
            }

            return (
                <Typography component="p" variant={additionalTextTypography} className={additionalTextClasses.root}>
                    {item.additionalText}
                </Typography>
            );
        },
        [additionalTextClasses.root, additionalTextTypography]
    );

    return (
        <FormControl error={showError} className={className}>
            {groupLabel && (
                <Typography variant="body1" className={classes.formLabel}>
                    {groupLabel}
                </Typography>
            )}
            <RadioGroup name={name} value={String(value)} onChange={onChange} onBlur={onBlur}>
                <div className={clsx(radioContainerClassName, radioContainerStyles.root)}>
                    {data.map(item => (
                        <div key={item.value}>
                            <FormControlLabel
                                key={item.value}
                                value={String(item.value)}
                                control={<Radio classes={{ ...radioClasses }} />}
                                label={<Typography variant={typography}>{item.label}</Typography>}
                                disabled={item.disabled}
                                classes={formControlClasses}
                            />
                            {renderAdditional(item)}
                        </div>
                    ))}
                </div>
            </RadioGroup>
            {showError && <FormHelperText classes={helperTextClasses}>{helperText}</FormHelperText>}
        </FormControl>
    );
};

export function FormRadioControl({ control, errors, ...props }: FormRadioControlProps): React.ReactElement {
    const { name, defaultValue } = props;
    return (
        <Controller
            render={({ field: { onChange, onBlur, value } }) => (
                <RadioControl
                    helperText={<FieldError errors={errors} name={name} />}
                    showError={shouldShowFormError({ name, errors })}
                    onBlur={onBlur}
                    onChange={onChange}
                    {...props}
                    value={value}
                />
            )}
            name={name}
            control={control}
            defaultValue={defaultValue}
        />
    );
}
