export default {
    LOGIN: {
        ROOT: "/login",
    },
    VERIFY: {
        EMAIL: "/verify-email",
    },
    LOGOUT: "/logout",
    CONSENT: "/consent",
    ERRORS: {
        INTERNAL: "/error",
        NOT_FOUND: "/not-found",
        EXPIRED_LOGIN_LINK: "/expired-login-link",
        EXPIRED_EMAIL_VERIFICATION_LINK: "/expired-email-verification-link",
    },
    PORTAL: {
        HOME: window.APP_SETTINGS.portalBaseUri,
        SIGN_UP: new URL(window.APP_SETTINGS.portalSignUpPath, window.APP_SETTINGS.portalBaseUri).toString(),
    },
};
