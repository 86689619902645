import { FooterLocalizationId } from "layout/footer/localization";
import { useFooterStyles } from "layout/footer/styles";
import { observer } from "mobx-react-lite";
import { Link } from "uiKit/links";

import { useLocalize } from "shared/hooks/useLocalize";
import { LocalizedComponentName } from "shared/localization/enums";
import { withLocalization } from "shared/localization/withLocalization";
import { useRootStore } from "shared/store/useRootStore";

const FooterElement = () => {
    const { classes } = useFooterStyles();
    const localize = useLocalize();

    const {
        rootStore: { publicLinksStore },
    } = useRootStore();

    return (
        <footer>
            <nav className={classes.navigation}>
                <Link
                    isOpenAtNewPage
                    link={publicLinksStore.privacyPolicyLink}
                    typographyVariant="caption"
                    label={localize(FooterLocalizationId.PrivacyPolicyLabel)}
                />
                <Link
                    isOpenAtNewPage
                    link={publicLinksStore.termsAndConditionsLink}
                    typographyVariant="caption"
                    label={localize(FooterLocalizationId.TermsAndConditionsLabel)}
                />
            </nav>
        </footer>
    );
};

export const Footer = withLocalization(observer(FooterElement), LocalizedComponentName.FooterLabelsLinks, true);
