import { ReactNode, useMemo } from "react";

import { CssBaseline, ThemeProvider } from "@mui/material";

import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { PublicLayout } from "layout/publicLayout";
import { TssCacheProvider } from "tss-react";
import ToastContainer from "uiKit/containers/toast/container";

import { CustomIntlProvider } from "shared/localization/CustomIntlProvider";
import { LocalizedComponentName } from "shared/localization/enums";
import { withLocalizations } from "shared/localization/withLocalization";
import { RequestContextProvider } from "shared/providers/RequestProvider";
import { RootStore } from "shared/store/rootStore";
import { RootStoreContext } from "shared/store/useRootStore";
import defaultTheme from "shared/theme/defaultTheme";

const LocalizedRequestContextProvider = withLocalizations(
    RequestContextProvider,
    [
        LocalizedComponentName.SharedMessages,
        LocalizedComponentName.AccountTypes,
        LocalizedComponentName.Notifications,
        LocalizedComponentName.ValidationMessages,
    ],
    false,
    true
);

const muiCache = createCache({
    key: "mui",
    prepend: true,
});

const tssCache = createCache({
    key: "tss",
});

export const AppProviders = ({ children }: { children?: ReactNode }) => {
    const store = useMemo(
        () => ({
            rootStore: new RootStore(),
        }),
        []
    );

    return (
        <RootStoreContext.Provider value={store}>
            <CustomIntlProvider>
                <CacheProvider value={muiCache}>
                    <TssCacheProvider value={tssCache}>
                        <ThemeProvider theme={defaultTheme}>
                            <CssBaseline />
                            <LocalizedRequestContextProvider>
                                <PublicLayout>{children}</PublicLayout>
                            </LocalizedRequestContextProvider>
                            <ToastContainer />
                        </ThemeProvider>
                    </TssCacheProvider>
                </CacheProvider>
            </CustomIntlProvider>
        </RootStoreContext.Provider>
    );
};
