import { Button, CircularProgress } from "@mui/material";

import { CommonResizableButtonsProps } from "uiKit/buttons/interfaces";
import { buttonSizeToTextVariantMapping, getButtonSpinnerSize, useButtonProgressStyles } from "uiKit/buttons/styles";
import { ButtonText } from "uiKit/buttons/text/ButtonText";
import { useTranslucentStyles } from "uiKit/buttons/translucentButton/styles";

import { useDoubleClickPreventer } from "shared/hooks/useDoubleClickPreventer";

export const TranslucentButton = ({
    id,
    label,
    palette,
    size = "m",
    startIcon,
    endIcon,
    disabled,
    isLoading,
    className,
    onClick,
    ...rest
}: CommonResizableButtonsProps) => {
    const { classes } = useTranslucentStyles({ palette: palette || "default", size });
    const { classes: progressClasses } = useButtonProgressStyles();
    const variant = size && buttonSizeToTextVariantMapping[size];
    const [isClickProcessing, onClickHandler] = useDoubleClickPreventer(onClick);

    return (
        <Button
            {...rest}
            id={id}
            variant="contained"
            focusRipple={false}
            startIcon={startIcon}
            endIcon={endIcon}
            disabled={isLoading || disabled || isClickProcessing}
            classes={classes}
            className={className}
            onClick={onClickHandler}
        >
            <ButtonText label={label} textVariant={variant} />
            {isLoading && <CircularProgress size={getButtonSpinnerSize(size)} className={progressClasses.root} />}
        </Button>
    );
};
