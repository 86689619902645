import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Grid, Typography } from "@mui/material";

import { ErrorLayout } from "layout/errorLayout";
import { SolidButton } from "uiKit/buttons/solidButton";
import { showSuccessToast } from "uiKit/containers/toast/utils";

import { ExpiredErrorTypes } from "pages/errors/expiredLink/constants";
import { EmailVerificationLocalizationId, LoginExpiredLocalizationId } from "pages/errors/expiredLink/localization";
import { useExpiredLinkErrorStyles } from "pages/errors/expiredLink/styles";
import { AuthMethod } from "pages/login/constants/enums";
import { apiPostUiRequestLoginCode } from "shared/api/auth/ui/apiPostUiRequestLoginCode";
import { apiPutUiResendEmailVerificationLink } from "shared/api/auth/ui/apiPutUiResendEmailVerificationLink";
import { LocalizeFunction, useLocalize } from "shared/hooks/useLocalize";
import { useRequestContext } from "shared/hooks/useRequest";
import { LocalizedComponentName } from "shared/localization/enums";
import { withLocalizations } from "shared/localization/withLocalization";
import routeNames from "shared/routes/routeNames";

interface ExpiredErrorProps {
    type?: ExpiredErrorTypes;
}

interface LocalizationDictionary {
    title: string;
    image: string;
    description: string;
    button: string;
    toastMessage: string;
}

const getLocalizationDictionary = (localize: LocalizeFunction, type?: ExpiredErrorTypes): LocalizationDictionary => {
    switch (type) {
        case ExpiredErrorTypes.Login:
            return {
                title: localize(LoginExpiredLocalizationId.ErrorLoginTitle),
                image: localize(LoginExpiredLocalizationId.Image),
                description: localize(LoginExpiredLocalizationId.ErrorLoginDescription),
                button: localize(LoginExpiredLocalizationId.ResendLinkButton),
                toastMessage: localize(LoginExpiredLocalizationId.ResendToastMessage),
            };

        case ExpiredErrorTypes.EmailVerification:
            return {
                title: localize(EmailVerificationLocalizationId.ErrorLoginTitle),
                image: localize(EmailVerificationLocalizationId.Image),
                description: localize(EmailVerificationLocalizationId.ErrorLoginDescription),
                button: localize(EmailVerificationLocalizationId.ResendLinkButton),
                toastMessage: localize(EmailVerificationLocalizationId.ResendToastMessage),
            };
        default:
            throw new Error("Unexpected type");
    }
};

const ExpiredLinkErrorPageElement = ({ type }: ExpiredErrorProps) => {
    const localize = useLocalize();
    const { classes } = useExpiredLinkErrorStyles();
    const navigate = useNavigate();
    const [search] = useSearchParams();
    const [linkWasSent, setLinkWasSent] = useState(false);

    const email = search.get("email") ?? undefined;
    const token = search.get("token") ?? undefined;
    const returnUrl = search.get("returnUrl") ?? undefined;

    const { executeRequest, isLoading } = useRequestContext();

    useEffect(() => {
        if (!token) {
            navigate(routeNames.ERRORS.NOT_FOUND);
        }
    }, [navigate, token]);

    const localizationDictionary = useMemo(() => {
        return getLocalizationDictionary(localize, type);
    }, [type, localize]);

    const onResendLink = useCallback(async () => {
        try {
            const request = async () => {
                switch (type) {
                    case ExpiredErrorTypes.Login:
                        return apiPostUiRequestLoginCode({ authMethod: AuthMethod.Email, email, returnUrl });
                    case ExpiredErrorTypes.EmailVerification:
                        return apiPutUiResendEmailVerificationLink({ token, returnUrl });
                    default:
                        throw new Error("Unexpected type");
                }
            };

            await executeRequest(request, "", []);
            showSuccessToast({ message: localizationDictionary.toastMessage });
            setLinkWasSent(true);
        } catch (e) {
            navigate(routeNames.ERRORS.NOT_FOUND);
        }
        // Query param always new, so we should ignore returnUrl
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [executeRequest, navigate, localizationDictionary.toastMessage, token, type]);

    return (
        <ErrorLayout imgSrc={localizationDictionary.image}>
            <Grid item sm={2} />
            <Grid item xs={7} className={classes.content}>
                <Typography variant="h1" className={classes.marginTopOneSpacing}>
                    {localizationDictionary.title}
                </Typography>
                <div className={classes.marginVerticalTwoSpacing}>
                    <Typography variant="body1">{localizationDictionary.description}</Typography>
                </div>
            </Grid>
            <Grid item xs={12} className={classes.stepBack}>
                <SolidButton
                    size="l"
                    palette="primary"
                    label={localizationDictionary.button}
                    onClick={onResendLink}
                    isLoading={isLoading}
                    disabled={linkWasSent}
                />
            </Grid>
        </ErrorLayout>
    );
};

export const ExpiredLinkErrorPage = withLocalizations(ExpiredLinkErrorPageElement, [
    LocalizedComponentName.RegistrationExpiredLinkErrorPage,
]);
