import { useFormContext } from "react-hook-form";

import { FormTextField } from "uiKit/inputs/textField";

import { LoginWithCredentialsLocalizationId } from "pages/login/steps/enterCredentials/localization";
import { EnterCredentialsFormValues } from "pages/login/steps/enterCredentials/schema";
import { useEnterCredentialsStyles } from "pages/login/steps/enterCredentials/styles";
import { useLocalize } from "shared/hooks/useLocalize";

export const EnterCredentialsFormContent = () => {
    const localize = useLocalize();
    const { classes } = useEnterCredentialsStyles();
    const {
        control,
        formState: { errors },
    } = useFormContext<EnterCredentialsFormValues>();

    return (
        <div className={classes.formContainer}>
            <FormTextField
                name="userName"
                label={localize(LoginWithCredentialsLocalizationId.UsernameLabel)}
                errors={errors}
                control={control}
            />
            <FormTextField
                name="password"
                label={localize(LoginWithCredentialsLocalizationId.PasswordLabel)}
                errors={errors}
                control={control}
                inputType="password"
            />
        </div>
    );
};
