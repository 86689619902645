import { makeStyles } from "tss-react/mui";

import { applicationPalettes, tabletBreakpoint } from "shared/theme/defaultTheme";

export const useFormControlStyles = makeStyles()(theme => ({
    formLabel: {
        marginBottom: theme.spacing(2),
    },
}));

export const useFormControlLabelStyles = makeStyles()(() => ({
    root: {
        marginLeft: 0,
        marginRight: 0,
        wordBreak: "break-all",
        alignItems: "center",
        textAlign: "left",
    },
}));

interface RadioContainerStylesProps {
    inline: boolean;
}

export const useRadioContainerStyles = makeStyles<RadioContainerStylesProps>()((theme, props) => ({
    root: {
        display: "flex",
        gap: theme.spacing(3),
        flexDirection: "column",
        [theme.breakpoints.up(tabletBreakpoint)]: {
            display: "flex",
            alignItems: "start",
            flexDirection: props.inline ? "row" : "column",
            gap: props.inline ? theme.spacing(4) : theme.spacing(3),
        },
    },
}));

export const useRadioStyles = makeStyles()(theme => ({
    root: {
        padding: 0,
        color: applicationPalettes.primary[500],
        marginRight: theme.spacing(),
        "&$checked": {
            color: applicationPalettes.primary[500],
        },
        "&:hover": {
            backgroundColor: applicationPalettes.primary[50],
        },
        "& svg": {
            fontSize: "24px",
            padding: theme.spacing(0.25),
        },
        "&.Mui-disabled": {
            color: applicationPalettes.default[200],
        },
    },
    checked: {},
    disabled: {},
}));
