/**
 * Summary: Add email verification request for hybris user.
 *
 * Tags: Ui
 */
import { AxiosRequestConfig } from "axios";

import { httpPost } from "shared/api/apiHandler";

export const API_POST_UI_EMAIL_URL = "api/v1/ui/email";

export const apiPostUiEmail = async (
    request?: ApiPostUiEmailRequest,
    config?: AxiosRequestConfig
): Promise<ApiPostUiEmailResponse> => {
    const result = await httpPost<ApiPostUiEmailResponse, ApiPostUiEmailRequest>(
        "",
        API_POST_UI_EMAIL_URL,
        request,
        config
    );
    return result.data;
};

/**
 *
 */
export type ApiPostUiEmailRequest = AddUserEmailVerificationRequest;

/**
 * Description: Success
 */
export type ApiPostUiEmailResponse = void;

interface AddUserEmailVerificationRequest {
    userName?: string;
    password?: string;
    email?: string;
    returnUrl?: string; // Return url. Example: /resolution-panel
}
