import { Avatar as MaterialAvatar, Typography } from "@mui/material";

import { AvatarProps } from "uiKit/containers/avatar/interfaces";
import { useAvatarStyles } from "uiKit/containers/avatar/styles";
import {
    formatUserNameForAvatar,
    getAvatarDimensionsBySize,
    getAvatarTypographyBySize,
} from "uiKit/containers/avatar/utils";

export const Avatar = ({ primaryName, secondaryName, src, size = "s" }: AvatarProps) => {
    const { classes } = useAvatarStyles(getAvatarDimensionsBySize(size));
    const typographyVariant = getAvatarTypographyBySize(size);

    return (
        <MaterialAvatar src={src} className={classes.avatar}>
            {!src && (
                <Typography variant={typographyVariant} className={classes.avatarText}>
                    {formatUserNameForAvatar(primaryName, secondaryName)}
                </Typography>
            )}
        </MaterialAvatar>
    );
};
