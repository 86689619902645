import { makeStyles } from "tss-react/mui";

import { TxtColor, applicationPalettes, elementWidthSize, tabletBreakpoint } from "shared/theme/defaultTheme";

export const useToastContainerStyles = makeStyles()(theme => ({
    root: {
        padding: 0,
        "& .Toastify__toast": {
            padding: 0,
            display: "flex",
            minHeight: theme.spacing(0),
            background: "transparent",
            borderRadius: theme.spacing(1),
        },
        "& .Toastify__toast-body": {
            margin: 0,
            padding: 0,
            display: "flex",
            flex: "1 1 auto",
        },
        [theme.breakpoints.up(tabletBreakpoint)]: {
            width: elementWidthSize.medium,
        },
    },
}));

interface AlertStylesProps {
    background: string;
    hasOnlyMessage: boolean;
    hasOnlyTitle: boolean;
    showCloseIcon: boolean;
}

export const useAlertStyles = makeStyles<AlertStylesProps>()(
    (theme, { hasOnlyMessage, hasOnlyTitle, background, showCloseIcon }) => ({
        root: {
            flex: "1 1 auto",
            background: applicationPalettes.primary[900],
            borderRadius: theme.spacing(1),
            padding: theme.spacing(1.5, 2.25),
            alignItems: hasOnlyMessage || hasOnlyTitle ? "center" : "initial",
        },
        message: {
            flex: "1 1 auto",
            padding: 0,
            "& *": {
                color: TxtColor.Txt8,
                wordBreak: "break-word",
            },
        },
        action: {
            alignItems: "flex-start",
            margin: 0,
            color: TxtColor.Txt8,
            // cannot reach close button by `classes` without this
            "& .MuiButtonBase-root": {
                padding: 0,
            },
        },
        icon: {
            marginRight: theme.spacing(2.25),
            padding: theme.spacing(0.25, 0, 0, 0),
            alignSelf: "flex-start",
            "& svg": {
                fontSize: "20px",
                color: TxtColor.Txt8,
                borderRadius: "100%",
                padding: theme.spacing(0.2),
                background: background,
            },
        },
        messageTypography: {
            marginTop: hasOnlyMessage ? 0 : theme.spacing(0.5),
            color: hasOnlyMessage ? TxtColor.Txt8 : TxtColor.Txt7,
        },
        titleTypography: {
            color: hasOnlyTitle && !showCloseIcon ? TxtColor.Txt7 : TxtColor.Txt8,
        },
    })
);
