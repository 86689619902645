import { makeStyles } from "tss-react/mui";

import { tabletBreakpoint } from "shared/theme/defaultTheme";

export const useEnterCredentialsStyles = makeStyles()(theme => ({
    title: {
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        justifyContent: "center",
        flexWrap: "wrap",
        [theme.breakpoints.up(tabletBreakpoint)]: {
            flexDirection: "row",
        },
    },
    form: {
        width: "100%",
        [theme.breakpoints.up(tabletBreakpoint)]: {
            width: "auto",
        },
    },
    formContainer: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(3),
    },
}));
