import { CheckCircleRounded, ErrorRounded, InfoRounded, WarningRounded } from "@mui/icons-material";
import { SvgIcon } from "@mui/material";

import clsx from "clsx";
import { useNoteIconStyles, useNoteStyles } from "uiKit/containers/note/styles";
import { NoteText } from "uiKit/containers/note/text";
import { getContainerWidthsBySize } from "uiKit/containers/utils";

const iconList = {
    info: InfoRounded,
    error: ErrorRounded,
    critical: ErrorRounded,
    warning: WarningRounded,
    success: CheckCircleRounded,
};

interface NoteProps {
    variant: "info" | "error" | "warning" | "success" | "critical";
    size?: "default" | "small" | "medium" | "big" | "auto" | "full";
    title?: React.ReactNode;
    text?: React.ReactNode;
    hideIcon?: boolean;
    children?: React.ReactNode;
    startIcon?: typeof SvgIcon;
}

export const Note = ({ variant, title, text, hideIcon, startIcon, children, size = "auto" }: NoteProps) => {
    const { classes } = useNoteStyles(getContainerWidthsBySize(size));
    const { classes: iconClasses } = useNoteIconStyles();
    return (
        <div className={clsx(classes.root, variant)}>
            <div className={classes.container}>
                {!hideIcon && title && (
                    <SvgIcon
                        component={startIcon || iconList[variant]}
                        classes={iconClasses}
                        className={variant}
                        viewBox="0 0 22 22"
                    />
                )}
                <NoteText title={title} text={text} />
            </div>

            {children}
        </div>
    );
};
