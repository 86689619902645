import { makeStyles } from "tss-react/mui";

import { applicationPalettes, whiteColor } from "shared/theme/defaultTheme";

export const useTitleStyles = makeStyles()(() => ({
    root: {
        color: whiteColor,
    },
}));

export const useTooltipStyles = makeStyles()(theme => ({
    tooltip: {
        margin: theme.spacing(1, 0, 0.75, 0),
        maxWidth: 330,
        zIndex: theme.zIndex.tooltip,
    },
    arrow: {
        "&:before": {
            backgroundColor: applicationPalettes.primary[900],
        },
        fontSize: 15,
    },
}));
