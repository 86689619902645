import { makeStyles } from "tss-react/mui";
import {
    ButtonStylesProps,
    buttonMaxWidth,
    getButtonBorderRadius,
    getButtonHeight,
    getButtonMinWidth,
    getButtonPaddings,
    getButtonWidth,
    getColorFromPalette,
} from "uiKit/buttons/styles";

import { applicationPalettes, tabletBreakpoint } from "shared/theme/defaultTheme";

export const useTranslucentStyles = makeStyles<ButtonStylesProps>()((theme, props) => ({
    root: {
        height: getButtonHeight(props),
        borderRadius: getButtonBorderRadius(props),
        padding: getButtonPaddings(props),
        boxShadow: "none",
        backgroundColor: getColorFromPalette(props, 2),
        width: "100%",
        [theme.breakpoints.up(tabletBreakpoint)]: {
            width: getButtonWidth(props),
            minWidth: getButtonMinWidth(props),
            maxWidth: buttonMaxWidth,
        },
        "@media (hover: hover)": {
            "&:hover": {
                backgroundColor: getColorFromPalette(props, 100),
                boxShadow: "none",
                color: getColorFromPalette(props, 700),
            },
        },
        "@media (hover: none)": {
            "&:hover": {
                backgroundColor: getColorFromPalette(props, 2),
                boxShadow: "none",
            },
        },
        "&:active": {
            backgroundColor: getColorFromPalette(props, 200),
            boxShadow: "none",
        },

        "&.Mui-disabled": {
            backgroundColor: getColorFromPalette(props, 2),
            boxShadow: "none",
            color: getColorFromPalette(props, 200),
        },
    },
    contained: {
        color: getColorFromPalette(props, 500),
    },
    focusVisible: {
        border: `3px solid ${applicationPalettes.default[100]}`,
    },
    disabled: {
        color: getColorFromPalette(props, 200),
    },
}));
