/**
 * Summary: Get current user details.
 *
 * Tags: Ui
 */
import { AxiosRequestConfig } from "axios";

import { httpGet } from "shared/api/apiHandler";

export const API_GET_UI_USERINFO_URL = "api/v1/ui/userinfo";

export const apiGetUiUserinfo = async (config?: AxiosRequestConfig): Promise<ApiGetUiUserinfoResponse> => {
    const result = await httpGet<ApiGetUiUserinfoResponse>("", API_GET_UI_USERINFO_URL, undefined, config);
    return result.data;
};

/**
 * Description: Success
 */
export type ApiGetUiUserinfoResponse = GetUserInfoResponse;

interface GetUserInfoResponse {
    isAuthenticated: boolean; // Is current user authenticated.
}
