import { Note } from "uiKit/containers/note";

import { useErrorNotificationStyles } from "pages/login/errorNotification/styles";
import { useLogInNotificationError } from "pages/login/errorNotification/useLogInNotificationError";

export const ErrorNotification = () => {
    const { classes } = useErrorNotificationStyles();

    const notificationErrorTitle = useLogInNotificationError();

    return (
        <div className={classes.notificationContainer}>
            {notificationErrorTitle ? <Note variant="error" size="medium" title={notificationErrorTitle} /> : null}
        </div>
    );
};
