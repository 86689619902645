import { makeStyles } from "tss-react/mui";
import { scrollStyles } from "uiKit/styles";

export const useConsentPageStyles = makeStyles()(theme => ({
    root: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        margin: "auto",
    },

    content: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },

    title: {
        marginBottom: theme.spacing(2),
    },

    scopes: {
        minWidth: 375,
        maxHeight: 630,
        padding: theme.spacing(4),
        ...scrollStyles,
    },
    scopesTitle: {
        marginBottom: theme.spacing(2),
    },
    scopesTableRow: {
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    },

    buttons: {
        marginTop: theme.spacing(4),
        display: "flex",
        gap: theme.spacing(1),
    },
}));
