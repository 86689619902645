import React, { useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Typography } from "@mui/material";

import { observer } from "mobx-react-lite";
import { SolidButton } from "uiKit/buttons/solidButton";

import { LoginStepContent } from "pages/login/stepContent";
import { VerifyEmailStore } from "pages/verifyEmail/store";
import { SuccessfulVerificationLocalizationId } from "pages/verifyEmail/successfulVerification/localization";
import { useSuccessfulVerificationStyles } from "pages/verifyEmail/successfulVerification/styles";
import { useLocalize } from "shared/hooks/useLocalize";
import { useRequestContext } from "shared/hooks/useRequest";
import { useStore } from "shared/hooks/useStore";
import routeNames from "shared/routes/routeNames";

const SuccessfulVerificationElement = (): React.ReactElement => {
    const localize = useLocalize();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const { isLoading } = useRequestContext();
    const { classes } = useSuccessfulVerificationStyles();

    const {
        store: { verificationEmail, authToken },
    } = useStore(VerifyEmailStore);

    const onSuccessfulVerification = useCallback(async () => {
        if (!authToken) {
            return;
        }

        navigate(routeNames.LOGIN.ROOT, { replace: true });
        searchParams.set("token", authToken);

        if (verificationEmail) {
            searchParams.set("email", verificationEmail);
        }

        setSearchParams(searchParams);
    }, [authToken, verificationEmail, navigate, searchParams, setSearchParams]);

    return (
        <LoginStepContent>
            <div className={classes.content}>
                <Typography variant="h1">
                    {localize(SuccessfulVerificationLocalizationId.SuccessfulVerificationTitle)}
                </Typography>
                <Typography className={classes.info}>
                    {localize(SuccessfulVerificationLocalizationId.SuccessfulVerificationText, {
                        Email: verificationEmail,
                    })}
                </Typography>
            </div>
            <SolidButton
                size="l"
                palette="primary"
                isLoading={isLoading}
                label={localize(SuccessfulVerificationLocalizationId.ContinueButton)}
                onClick={onSuccessfulVerification}
            />
        </LoginStepContent>
    );
};

export const SuccessfulVerification = observer(SuccessfulVerificationElement);
