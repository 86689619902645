import { Done } from "@mui/icons-material";
import { Typography } from "@mui/material";

import { Avatar } from "uiKit/containers/avatar";

import { useAccountViewStyles } from "pages/login/steps/chooseAccount/styles";

interface AccountViewProps {
    primaryName: string;
    secondaryName?: string;
    accountType: string;
    isSelected: boolean;
    photoUrl?: string;
}

export const AccountView = ({ primaryName, secondaryName, accountType, photoUrl, isSelected }: AccountViewProps) => {
    const { classes } = useAccountViewStyles({ isSelected });

    return (
        <div className={classes.root}>
            <Avatar primaryName={primaryName} secondaryName={secondaryName} src={photoUrl} size="xs" />
            <div className={classes.name}>
                <Typography variant="body5">
                    {primaryName} {secondaryName}
                </Typography>
                <Typography variant="caption">{accountType}</Typography>
            </div>
            {isSelected && <Done className={classes.selectedIcon} />}
        </div>
    );
};
