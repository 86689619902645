import React, { useCallback, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { observer } from "mobx-react-lite";

import { LoginStep } from "pages/login/constants/enums";
import { LoginByTokenErrorCodes } from "pages/login/steps/processEmailToken/constants";
import { useProcessEmailTokenStyles } from "pages/login/steps/processEmailToken/styles";
import { SignInStore } from "pages/login/store";
import { isAxios400Error } from "shared/api/utils";
import { useStore } from "shared/hooks/useStore";
import routeNames from "shared/routes/routeNames";

import { FullscreenLoader } from "components/fullscreenLoader";

const ProcessEmailTokenElement: React.FC = () => {
    const [search, setSearchParams] = useSearchParams();
    const token = search.get("token") ?? undefined;
    const navigate = useNavigate();
    const { classes } = useProcessEmailTokenStyles();

    const {
        store: { loginByToken, setStep },
    } = useStore(SignInStore);

    const handleToken = useCallback(async () => {
        try {
            await loginByToken(token ?? "");
        } catch (e) {
            if (!isAxios400Error(e)) {
                return;
            }

            if (
                e.response.data.errors.some(
                    it =>
                        it.code === LoginByTokenErrorCodes.AuthTokenExpired ||
                        it.code === LoginByTokenErrorCodes.AuthTokenError
                )
            ) {
                navigate(
                    { pathname: routeNames.ERRORS.EXPIRED_LOGIN_LINK, search: search.toString() },
                    { replace: true }
                );
            } else {
                search.delete("token");
                setSearchParams(search);
                setStep(LoginStep.EnterEmail);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        handleToken();
    }, [handleToken]);

    return (
        <div className={classes.root}>
            <FullscreenLoader />
        </div>
    );
};

export const ProcessEmailToken = observer(ProcessEmailTokenElement);
