import React, { useCallback, useMemo } from "react";
import { Controller } from "react-hook-form";
import { ReactInputMask } from "react-input-mask";

import { Error } from "@mui/icons-material";
import { InputAdornment, TextField as MaterialTextField, useTheme } from "@mui/material";

import uniqueId from "lodash/uniqueId";
import { Tooltip } from "uiKit/containers/tooltip";
import { FieldError } from "uiKit/inputs/fieldError";
import { shouldShowFormError } from "uiKit/inputs/fieldError/utils";
import { CurrencyInputComponentParams } from "uiKit/inputs/inputComponents/CurrencyInput";
import { IntegerInputComponentParams } from "uiKit/inputs/inputComponents/IntegerInput";
import { useFormHelperStyles } from "uiKit/inputs/styles";
import { MultilineTextFieldDefaults } from "uiKit/inputs/textField/constants";
import { FormTextFieldProps, InputComponentParams, InputType, TextFieldProps } from "uiKit/inputs/textField/interfaces";
import { useInputStyles, useStartAdornmentStyles, useTextFieldStyles } from "uiKit/inputs/textField/styles";
import { getTextFieldPaddingBySize, getTextFieldWidthsBySize } from "uiKit/inputs/textField/utils";
import { InputLabel } from "uiKit/labels/inputLabel";

import { useTextOverflow } from "shared/hooks/useTextOverflow";
import { sanitizeString } from "shared/utils/string.utils";

export function TextField({
    label,
    optionalText,
    helperText,
    showError,
    startIcon,
    mask,
    onChange,
    onBlur,
    value,
    multiline,
    endIcon,
    inputRef,
    className,
    size = "auto",
    minRows = MultilineTextFieldDefaults.DEFAULT_ROWS,
    maxRows = MultilineTextFieldDefaults.MAX_ROWS,
    inputType = "text",
    inputInfoText,
    onKeyDown,
    disabled,
    readonly,
    ...props
}: TextFieldProps): React.ReactElement {
    const id = useMemo(() => uniqueId("textField-"), []);
    const theme = useTheme();
    const { classes: textFieldClasses } = useTextFieldStyles(getTextFieldWidthsBySize(size));
    const { classes: formHelperClasses } = useFormHelperStyles();
    const { classes: inputStyles } = useInputStyles({ ...getTextFieldPaddingBySize(theme, size), readonly });
    const { classes: startAdornmentClasses } = useStartAdornmentStyles();
    const inputComponentParam = getInputComponent(inputType);
    const { isTextOverflow, contentRef } = useTextOverflow<HTMLTextAreaElement | HTMLInputElement>();
    const inputLabelProps = useMemo(
        () => ({
            disabled,
            classes: inputStyles,
        }),
        [disabled, inputStyles]
    );
    const inputProps = useMemo(
        () => ({
            className,
            ref: inputRef,
            inputRef: mask ? undefined : contentRef,
            endAdornment:
                showError || endIcon ? (
                    <>
                        {endIcon && <InputAdornment position="end">{endIcon}</InputAdornment>}
                        {showError && !multiline && (
                            <InputAdornment position="end">
                                <Error className="inputErrorIcon" viewBox="2 2 20 20" />
                            </InputAdornment>
                        )}
                    </>
                ) : undefined,
            startAdornment: startIcon ? (
                <InputAdornment position="start" classes={{ ...startAdornmentClasses }}>
                    {startIcon}
                </InputAdornment>
            ) : undefined,
            classes: inputStyles,
            inputComponent: inputComponentParam ? inputComponentParam.inputComponent : undefined,
            type: inputType === "password" ? inputType : undefined,
        }),
        [
            className,
            inputRef,
            mask,
            contentRef,
            showError,
            endIcon,
            multiline,
            startIcon,
            startAdornmentClasses,
            inputStyles,
            inputComponentParam,
            inputType,
        ]
    );
    const isTextFieldDisabled = disabled || readonly;

    const trimAndRemoveTagsOnBlur = useCallback(
        (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            const sanitizedValue = sanitizeString(event.target.value);
            if (inputType === "text") {
                onChange?.({
                    ...event,
                    target: {
                        ...event.target,
                        value: sanitizedValue,
                    },
                });
            }
            onBlur?.(event);
        },
        [onBlur, onChange, inputType]
    );

    const textArea = useMemo(() => {
        function formatValue(inputValue: string | number | null | undefined) {
            switch (inputValue) {
                case undefined:
                case null:
                    return "";
                case 0:
                case "0":
                    return "0";
                default:
                    return inputValue;
            }
        }

        const optionalInputProps = !mask
            ? {
                  value: formatValue(value),
                  onChange,
                  onBlur: trimAndRemoveTagsOnBlur,
              }
            : {};

        return (
            <MaterialTextField
                multiline={multiline}
                minRows={minRows}
                maxRows={maxRows}
                variant="outlined"
                error={showError}
                InputProps={inputProps}
                InputLabelProps={inputLabelProps}
                FormHelperTextProps={{ classes: formHelperClasses }}
                classes={textFieldClasses}
                {...props}
                {...optionalInputProps}
                disabled={isTextFieldDisabled}
                id={id}
                helperText={showError ? helperText : null}
                onKeyDown={onKeyDown}
            />
        );
    }, [
        formHelperClasses,
        helperText,
        id,
        inputLabelProps,
        inputProps,
        isTextFieldDisabled,
        mask,
        maxRows,
        minRows,
        multiline,
        onChange,
        onKeyDown,
        props,
        showError,
        textFieldClasses,
        trimAndRemoveTagsOnBlur,
        value,
    ]);

    return (
        <div className="input-container">
            <InputLabel
                label={label}
                htmlFor={id}
                disabled={disabled}
                optionalText={optionalText}
                inputInfoText={inputInfoText}
            />
            {mask ? (
                <ReactInputMask
                    mask={mask}
                    value={value}
                    onChange={onChange}
                    onBlur={trimAndRemoveTagsOnBlur}
                    disabled={disabled}
                >
                    {textArea}
                </ReactInputMask>
            ) : (
                <Tooltip
                    withoutEnterTouchDelay
                    title={contentRef.current?.value || ""}
                    disabled={!isTextFieldDisabled || !isTextOverflow}
                >
                    {textArea}
                </Tooltip>
            )}
        </div>
    );
}

const getInputComponent = (inputType: InputType): InputComponentParams | undefined => {
    if (inputType === "currency") {
        return CurrencyInputComponentParams;
    }
    if (inputType === "integer") {
        return IntegerInputComponentParams;
    }
    return undefined;
};

export function FormTextField({ control, errors, defaultValue, ...props }: FormTextFieldProps): React.ReactElement {
    const { name } = props;
    return (
        <Controller
            render={({ field: { onBlur, onChange, value } }) => (
                <TextField
                    helperText={<FieldError errors={errors} name={name} />}
                    showError={shouldShowFormError({ name, errors })}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    {...props}
                />
            )}
            name={name}
            control={control}
            defaultValue={defaultValue}
        />
    );
}
