import { SvgIcon } from "@mui/material";

import { IconButton } from "uiKit/buttons/iconButton";
import { ButtonSize } from "uiKit/buttons/interfaces";
import { useTooltipIconButtonStyles } from "uiKit/buttons/tooltipIconButton/styles";
import { Tooltip } from "uiKit/containers/tooltip";

import { ApplicationColorPalette } from "shared/theme/defaultTheme";

type TooltipIconButtonVariant = ApplicationColorPalette;

interface TooltipIconButtonProps {
    className?: string;
    onClick?: () => void;
    disabled?: boolean;
    title: string;
    icon: typeof SvgIcon;
    size?: ButtonSize;
    isHidden?: boolean;
    id?: string;
    testId?: string;
    variant?: TooltipIconButtonVariant;
}

export const TooltipIconButton = ({
    className,
    title,
    onClick,
    disabled,
    icon,
    size,
    id,
    testId,
    isHidden = false,
    variant = "primary",
}: TooltipIconButtonProps): JSX.Element | null => {
    const { classes } = useTooltipIconButtonStyles();

    if (isHidden) {
        return null;
    }

    return (
        <div className={className}>
            <Tooltip
                title={title}
                titleVariant="body2"
                arrow
                placement="top"
                withoutEnterTouchDelay={!onClick}
                withoutLeaveTouchDelay={!onClick}
            >
                <span className={classes.wrapper}>
                    <IconButton
                        data-testid={testId ?? title}
                        variant={variant}
                        disabled={disabled}
                        onClick={onClick}
                        noShadow
                        size={size}
                        icon={icon}
                        id={id}
                    />
                </span>
            </Tooltip>
        </div>
    );
};
