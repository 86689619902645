import { action, makeObservable, observable, runInAction } from "mobx";

import { apiGetSettingsPrivacyPolicyAndTermsAndConditions } from "shared/api/spaceStone/settings/apiGetSettingsPrivacyPolicyAndTermsAndConditions";

export class PublicLinksStore {
    @observable public privacyPolicyLink?: string;
    @observable public termsAndConditionsLink?: string;

    constructor() {
        makeObservable(this);
    }

    @action.bound
    public async fetchLinks(): Promise<void> {
        const response = await apiGetSettingsPrivacyPolicyAndTermsAndConditions();
        runInAction(() => {
            this.privacyPolicyLink = response.privacyPolicy;
            this.termsAndConditionsLink = response.termsConditions;
        });
    }
}
