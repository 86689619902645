import { ObjectSchema, object } from "yup";

import { EnterEmailLocalizationId } from "pages/login/steps/enterEmail/localization";
import { LocalizeFunction } from "shared/hooks/useLocalize";
import { requiredEmailValidator } from "shared/validation/validation.email";

export interface EnterEmailFormValues {
    email: string;
}

export const createEmailSchema = (localize: LocalizeFunction): ObjectSchema<EnterEmailFormValues> =>
    object().shape({
        email: requiredEmailValidator(localize, localize(EnterEmailLocalizationId.Email)),
    });
