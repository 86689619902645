import { useCallback, useMemo } from "react";

import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { Typography } from "@mui/material";

import { observer } from "mobx-react-lite";
import { SolidButton } from "uiKit/buttons/solidButton";
import { TranslucentButton } from "uiKit/buttons/translucentButton";
import { RadioControl } from "uiKit/inputs/radioControl/RadioControl";

import { AuthMethod } from "pages/login/constants/enums";
import { LoginStepContent } from "pages/login/stepContent";
import { AuthWayLocalizationId } from "pages/login/steps/authWay/localization";
import { useAuthWayStyles } from "pages/login/steps/authWay/styles";
import { SignInStore } from "pages/login/store";
import { useLocalize } from "shared/hooks/useLocalize";
import { useRequestContext } from "shared/hooks/useRequest";
import { useStore } from "shared/hooks/useStore";

const AuthWayElement = () => {
    const {
        store: {
            requestLoginCode,
            goBack,
            setSelectedAuthMethod,
            loginState: { email, phone, selectedAuthMethod },
        },
    } = useStore(SignInStore);

    const { classes } = useAuthWayStyles();
    const localize = useLocalize();

    const { isLoading, executeRequest } = useRequestContext();

    const handleChange = useCallback(
        (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
            setSelectedAuthMethod(Number(value));
        },
        [setSelectedAuthMethod]
    );

    const authWays = useMemo(
        () => [
            {
                disabled: isLoading,
                value: AuthMethod.Email,
                label: `${localize(AuthWayLocalizationId.MagicLinkByEmail)}: ${email}`,
            },
            {
                disabled: isLoading,
                value: AuthMethod.Sms,
                label: `${localize(AuthWayLocalizationId.OneTimeCodeBySms)}: ${phone}`,
            },
        ],
        [email, isLoading, localize, phone]
    );

    const handleNextButtonClick = useCallback(() => {
        executeRequest(requestLoginCode, "", []);
    }, [executeRequest, requestLoginCode]);

    return (
        <LoginStepContent>
            <Typography variant="h1" className={classes.title}>
                {localize(AuthWayLocalizationId.LogIn)}
            </Typography>
            <Typography className={classes.logInDescription}>{localize(AuthWayLocalizationId.Description)}</Typography>
            <RadioControl
                className={classes.radioControl}
                data={authWays}
                value={selectedAuthMethod}
                onChange={handleChange}
            />
            <div className={classes.buttonContainer}>
                <SolidButton
                    size="l"
                    palette="primary"
                    isLoading={isLoading}
                    label={
                        selectedAuthMethod === AuthMethod.Email
                            ? localize(AuthWayLocalizationId.GetMagicLinkButton)
                            : localize(AuthWayLocalizationId.GetOneTimeCodeButton)
                    }
                    onClick={handleNextButtonClick}
                />
                <TranslucentButton
                    id="back-button"
                    size="l"
                    palette="primary"
                    startIcon={<ArrowBackIcon />}
                    disabled={isLoading}
                    label={localize(AuthWayLocalizationId.BackButton)}
                    onClick={goBack}
                />
            </div>
        </LoginStepContent>
    );
};

export const AuthWay = observer(AuthWayElement);
