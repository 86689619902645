import { ReactNode } from "react";
import { IntlProvider } from "react-intl";

import { observer } from "mobx-react-lite";

import { useRootStore } from "shared/store/useRootStore";

const CustomIntlProviderComponent = ({ children }: { children?: ReactNode }) => {
    const {
        rootStore: {
            localizationStore: { locale, messages },
        },
    } = useRootStore();

    return (
        <IntlProvider locale={locale} messages={messages}>
            {children}
        </IntlProvider>
    );
};

export const CustomIntlProvider = observer(CustomIntlProviderComponent);
