import { makeStyles } from "tss-react/mui";
import { getMainScrollbarStyles } from "uiKit/styles";

import { mediumBreakpoint } from "shared/theme/defaultTheme";

export const usePublicLayoutStyles = makeStyles()(theme => ({
    root: {
        display: "flex",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    content: {
        width: "100%",
        display: "flex",
        ...getMainScrollbarStyles(theme),
        [theme.breakpoints.down(mediumBreakpoint)]: {
            "& .left-panel": {
                display: "none",
            },
        },
    },
}));
