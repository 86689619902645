import { makeStyles } from "tss-react/mui";

export const useSuccessfulVerificationStyles = makeStyles()(theme => ({
    content: {
        textAlign: "center",
        maxWidth: "630px",
    },
    info: {
        margin: theme.spacing(4, 0, 13, 0),
    },
}));
