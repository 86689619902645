import { makeStyles } from "tss-react/mui";

import { TxtColor } from "shared/theme/defaultTheme";

export const useFormHelperStyles = makeStyles()({
    root: {
        "&.Mui-disabled": {
            color: TxtColor.Txt6,
        },
    },
    contained: {
        marginLeft: "0",
        lineHeight: "16px",
        fontSize: "12px",
        color: TxtColor.Txt3,
    },
    error: {},
});

export const useAdditionalTextStyles = makeStyles()(theme => ({
    root: {
        color: TxtColor.Txt3,
        margin: theme.spacing(1, 2, 0, 4),
    },
}));
