/**
 * Summary: Login via Email or SMS.
 *
 * Tags: Ui
 */
import { AxiosRequestConfig } from "axios";

import { httpPost } from "shared/api/apiHandler";

export const API_POST_UI_LOGIN_URL = "api/v1/ui/login";

export const apiPostUiLogin = async (
    request?: ApiPostUiLoginRequest,
    config?: AxiosRequestConfig
): Promise<ApiPostUiLoginResponse> => {
    const result = await httpPost<ApiPostUiLoginResponse, ApiPostUiLoginRequest>(
        "",
        API_POST_UI_LOGIN_URL,
        request,
        config
    );
    return result.data;
};

/**
 *
 */
export type ApiPostUiLoginRequest = LoginRequest;

/**
 * Description: Success
 */
export type ApiPostUiLoginResponse = LoginResponse;

interface LoginRequest {
    authMethod: 1 | 2; // Auth method. Example: 1
    email?: string; // User's email. Example: agent@mail.com
    code?: string; // Sms code. Example: 1234
    token?: string; // Email token. Example: 1234
    returnUrl?: string; // Return url. Example: /resolution-panel
    accountId?: number; // Account ID. Example: 1234
}

interface LookUpModel {
    id: number;
    text: string;
}

interface FileInfoLightDto {
    fileId: number;
    downloadUrl: string;
}

interface AuthenticatedUserAccountDetails {
    accountId: number;
    accountName: string;
    accountType: LookUpModel;
    accountIndex: number;
    photo?: FileInfoLightDto;
}

interface LoginResponse {
    validReturnUrl?: string; // Provided return url. Null is returned if it's not valid. Example: /resolution-panel
    isAccountSelectionRequired: boolean; // Is account selection required.
    firstName: string; // First name.
    lastName: string; // Last name.
    accounts?: AuthenticatedUserAccountDetails[]; // Accounts.
}
