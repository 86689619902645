import { makeStyles } from "tss-react/mui";
import { scrollStyles } from "uiKit/styles";

import { applicationPalettes, tabletBreakpoint } from "shared/theme/defaultTheme";

interface AccountViewStylesProps {
    isSelected: boolean;
}

export const useChooseAccountStyles = makeStyles()(theme => ({
    title: {
        marginBottom: theme.spacing(4),
        textAlign: "center",
    },
    accounts: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1),
        marginBottom: theme.spacing(6),
        maxHeight: "480px",
        ...scrollStyles,
        [theme.breakpoints.down(tabletBreakpoint)]: {
            maxHeight: "initial",
        },
    },
}));

export const useAccountViewStyles = makeStyles<AccountViewStylesProps>()((theme, { isSelected }) => ({
    root: {
        display: "flex",
        gap: theme.spacing(1),
        padding: theme.spacing(1),
        borderRadius: "4px",
        background: isSelected ? applicationPalettes.primary[2] : "none",
        width: "360px",
        alignItems: "center",
        [theme.breakpoints.down(tabletBreakpoint)]: {
            width: "100%",
        },
        position: "relative",
        "&:hover": {
            cursor: "pointer",
            background: applicationPalettes.primary[2],
        },
    },
    name: {
        display: "flex",
        flexDirection: "column",
        marginRight: theme.spacing(6),
    },
    selectedIcon: {
        color: applicationPalettes.primary[500],
        position: "absolute",
        right: theme.spacing(2),
    },
}));
