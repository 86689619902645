import { IconButton as MaterialIconButton, SvgIcon } from "@mui/material";

import { useIconButtonStyles } from "uiKit/buttons/iconButton/styles";
import { ButtonSize, Interfaces } from "uiKit/buttons/interfaces";

import { ApplicationColorPalette } from "shared/theme/defaultTheme";

export interface IconButtonProps extends Interfaces {
    id?: string;
    icon: typeof SvgIcon;
    variant?: ApplicationColorPalette;
    noShadow?: boolean;
    size?: ButtonSize;
    buttonClasses?: Record<string, string>;
    iconClasses?: Record<string, string>;
}

export const IconButton = ({
    id,
    icon,
    variant,
    noShadow,
    size,
    className,
    buttonClasses,
    iconClasses,
    href,
    openInNewTab,
    ...rest
}: IconButtonProps) => {
    const {
        classes: { icon: iconRoot, ...classes },
    } = useIconButtonStyles({
        noShadow,
        variant: variant || "default",
    });
    const Icon = icon;
    const additionalProps = { ...rest, href, target: openInNewTab ? "_blank" : "_self" };
    return (
        <MaterialIconButton
            id={id}
            className={className}
            classes={{ ...classes, ...buttonClasses }}
            size={size === "s" ? "small" : "medium"}
            disableRipple
            {...additionalProps}
        >
            <Icon classes={{ root: iconRoot, ...iconClasses }} />
        </MaterialIconButton>
    );
};
