import { action, makeObservable, observable, runInAction } from "mobx";

import { apiGetUiUserinfo } from "shared/api/auth/ui/apiGetUiUserinfo";
import { LocalizationStore } from "shared/localization/localizationStore";
import { PublicLinksStore } from "shared/store/publicLinksStore";

export class RootStore {
    @observable public readonly localizationStore: LocalizationStore;
    @observable public readonly publicLinksStore: PublicLinksStore;

    @observable public isAuthenticated = false;

    constructor() {
        makeObservable(this);

        this.localizationStore = new LocalizationStore();
        this.publicLinksStore = new PublicLinksStore();
    }

    @action.bound
    public async fetchUserInfo(): Promise<void> {
        const response = await apiGetUiUserinfo();
        runInAction(() => {
            this.isAuthenticated = response.isAuthenticated;
        });
    }
}
