import { alpha } from "@mui/material";

import { makeStyles } from "tss-react/mui";

import { TxtColor } from "shared/theme/defaultTheme";

interface Props {
    inner: boolean;
}

const useFullscreenLoaderStyles = makeStyles<Props>()((_, { inner }) => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,

        minHeight: inner ? "50vh" : "100vh",
        backgroundColor: inner ? alpha(TxtColor.Txt8, 0.6) : "unset",
    },
}));

export default useFullscreenLoaderStyles;
