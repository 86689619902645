import { makeStyles } from "tss-react/mui";

import { TxtColor, mobileBreakpoint, tabletBreakpoint } from "shared/theme/defaultTheme";

export const useAuthWayStyles = makeStyles()(theme => ({
    radioControl: {
        marginBottom: theme.spacing(11),
        [theme.breakpoints.down(mobileBreakpoint)]: {
            marginBottom: theme.spacing(9),
        },
    },
    title: {
        marginBottom: theme.spacing(4),
    },
    buttonContainer: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(3),
        [theme.breakpoints.down(tabletBreakpoint)]: {
            width: "100%",
        },
    },
    logInDescription: {
        textAlign: "center",
        marginBottom: theme.spacing(6),
        width: "100%",
        color: TxtColor.Txt2,
    },
}));
