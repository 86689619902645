export enum AuthWayLocalizationId {
    LogIn = "sign-in-auth-way.log_in_title",
    Description = "sign-in-auth-way.description_paragraph",
    MagicLinkByEmail = "sign-in-auth-way.magic_link_by_email_option",
    OneTimeCodeBySms = "sign-in-auth-way.one_time_code_by_sms_option",
    GetMagicLinkButton = "sign-in-auth-way.get_magic_link_button",
    GetOneTimeCodeButton = "sign-in-auth-way.get_one_time_code_button",
    BackButton = "sign-in-auth-way.back_button",
    StepPicture = "sign-in-auth-way.step_picture",
}
