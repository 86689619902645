import { makeStyles } from "tss-react/mui";

import { TxtColor, tabletBreakpoint } from "shared/theme/defaultTheme";

export const useEnterEmailStyles = makeStyles()(theme => ({
    disclaimer: {
        textAlign: "center",
        color: TxtColor.Txt2,
    },
    verticalDivider: {
        marginBottom: theme.spacing(5),
    },
    form: {
        width: "100%",
        [theme.breakpoints.up(tabletBreakpoint)]: {
            width: "auto",
        },
    },
    button: {
        marginTop: theme.spacing(5),
    },
    helperText: {
        marginTop: theme.spacing(3),
        color: TxtColor.Txt2,
    },

    formContainer: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(3),
    },
}));
