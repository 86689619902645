/**
 * Summary: Resend hybris user change email request.
 *
 * Tags: Ui
 */
import { AxiosRequestConfig } from "axios";

import { httpPut } from "shared/api/apiHandler";

export const API_PUT_UI_RESEND_EMAIL_VERIFICATION_LINK_URL = "api/v1/ui/resend-email-verification-link";

export const apiPutUiResendEmailVerificationLink = async (
    request?: ApiPutUiResendEmailVerificationLinkRequest,
    config?: AxiosRequestConfig
): Promise<ApiPutUiResendEmailVerificationLinkResponse> => {
    const result = await httpPut<
        ApiPutUiResendEmailVerificationLinkResponse,
        ApiPutUiResendEmailVerificationLinkRequest
    >("", API_PUT_UI_RESEND_EMAIL_VERIFICATION_LINK_URL, request, config);
    return result.data;
};

/**
 *
 */
export type ApiPutUiResendEmailVerificationLinkRequest = ResendUserEmailVerificationRequest;

/**
 * Description: Success
 */
export type ApiPutUiResendEmailVerificationLinkResponse = void;

interface ResendUserEmailVerificationRequest {
    token?: string;
    returnUrl?: string; // Return url. Example: /resolution-panel
}
