/**
 * Summary: Grant or deny consent.
 *
 * Tags: Ui
 */
import { AxiosRequestConfig } from "axios";

import { httpPut } from "shared/api/apiHandler";

export const API_PUT_UI_CONSENT_URL = "api/v1/ui/consent";

export const apiPutUiConsent = async (
    request?: ApiPutUiConsentRequest,
    config?: AxiosRequestConfig
): Promise<ApiPutUiConsentResponse> => {
    const result = await httpPut<ApiPutUiConsentResponse, ApiPutUiConsentRequest>(
        "",
        API_PUT_UI_CONSENT_URL,
        request,
        config
    );
    return result.data;
};

/**
 *
 */
export type ApiPutUiConsentRequest = GrantOrDenyConsentRequest;

/**
 * Description: Success
 */
export type ApiPutUiConsentResponse = GrantOrDenyConsentResponse;

interface GrantOrDenyConsentRequest {
    deny: boolean; // Whether to deny or grant consent.
    returnUrl?: string; // Return url. Example: /resolution-panel
}

interface GrantOrDenyConsentResponse {
    validReturnUrl?: string; // Provided return url. Null is returned if it's not valid. Example: /resolution-panel
}
