export enum LocalizedComponentName {
    SharedMessages = "shared-messages",
    Notifications = "notifications",
    AccountTypes = "account-types",
    SignIn = "sign-in",
    SignInAuthWay = "sign-in-auth-way",
    SignInChooseAccount = "sign-in-choose-account",
    SignInCheckInbox = "sign-in-check-inbox",
    SignInConsent = "sign-in-consent",
    SignInEnterEmail = "sign-in-enter-email",
    SignInEnterCredentials = "sign-in-enter-credential",
    SignInPhoneCode = "sign-in-phone-code",
    SignInVerifyEmail = "sign-in-verify-email",
    RegistrationExpiredLinkErrorPage = "registration-link-expired",
    NotFoundErrorPage = "error-404",
    InternalErrorPage = "error-500",
    ValidationMessages = "validation-messages",
    FooterLabelsLinks = "footer-labels-links",
}
