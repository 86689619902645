/**
 * Summary: Get possible login options (via SMS code or only by email).
 *
 * Tags: Ui
 */
import { AxiosRequestConfig } from "axios";

import { httpGet } from "shared/api/apiHandler";

export const API_GET_UI_LOGIN_OPTIONS_URL = "api/v1/ui/login-options";

export const apiGetUiLoginOptions = async (
    request?: ApiGetUiLoginOptionsRequest,
    config?: AxiosRequestConfig
): Promise<ApiGetUiLoginOptionsResponse> => {
    const result = await httpGet<ApiGetUiLoginOptionsResponse, ApiGetUiLoginOptionsRequest>(
        "",
        API_GET_UI_LOGIN_OPTIONS_URL,
        request,
        config
    );
    return result.data;
};

/**
 *
 */
export interface ApiGetUiLoginOptionsRequest {
    email?: string; // User's email. Example: tenant@mail.com
}

/**
 * Description: Success
 */
export type ApiGetUiLoginOptionsResponse = GetLoginOptionsResponse;

interface GetLoginOptionsResponse {
    email: string;
    phone?: string;
    isLocked?: boolean;
}
