import React from "react";

import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";

import { observer } from "mobx-react-lite";
import { SolidButton } from "uiKit/buttons/solidButton";
import { TranslucentButton } from "uiKit/buttons/translucentButton";

import { useNavigationButtonsStyles } from "pages/login/navigationButtons/styles";
import { SignInStore } from "pages/login/store";
import { useRequestContext } from "shared/hooks/useRequest";
import { useStore } from "shared/hooks/useStore";

interface NavigationButtonsProps {
    submitButtonLabel?: string;
    backButtonLabel?: string;
}

const NavigationButtonsElement = ({
    submitButtonLabel,
    backButtonLabel,
}: NavigationButtonsProps): React.ReactElement => {
    const { classes } = useNavigationButtonsStyles();
    const { isLoading } = useRequestContext();

    const {
        store: { goBack, isLocked },
    } = useStore(SignInStore);

    return (
        <div className={classes.buttonContainer}>
            {submitButtonLabel && (
                <SolidButton
                    size="l"
                    type="submit"
                    palette="primary"
                    isLoading={isLoading}
                    label={submitButtonLabel}
                    disabled={isLocked}
                />
            )}
            {backButtonLabel && (
                <TranslucentButton
                    id="arrow-back-button"
                    size="l"
                    palette="primary"
                    label={backButtonLabel}
                    disabled={isLoading}
                    startIcon={<ArrowBackIcon />}
                    onClick={goBack}
                />
            )}
        </div>
    );
};

export const NavigationButtons = observer(NavigationButtonsElement);
