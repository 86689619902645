import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { Grid, Typography } from "@mui/material";

import { ErrorLayout } from "layout/errorLayout";
import { SolidButton } from "uiKit/buttons/solidButton";

import { Error500LocalizationId } from "pages/errors/internalError/localization";
import { useInternalErrorStyles } from "pages/errors/internalError/styles";
import { useLocalize } from "shared/hooks/useLocalize";
import { LocalizedComponentName } from "shared/localization/enums";
import { withLocalization } from "shared/localization/withLocalization";

const InternalErrorPageElement = () => {
    const { classes } = useInternalErrorStyles();
    const localize = useLocalize();
    const navigate = useNavigate();

    const goBack = useCallback(() => navigate(-1), [navigate]);

    return (
        <ErrorLayout imgSrc={localize(Error500LocalizationId.Image)}>
            <Grid item sm={2} />
            <Grid item xs={10} className={classes.content}>
                <Typography variant="h1" className={classes.marginTopOneSpacing}>
                    {localize(Error500LocalizationId.ErrorTitle)}
                </Typography>
                <div className={classes.marginVerticalTwoSpacing}>
                    <Typography variant="body1">
                        {localize(Error500LocalizationId.ErrorDescription)}&nbsp;
                        {localize(Error500LocalizationId.ContactUsLink)}
                    </Typography>
                </div>
            </Grid>
            <Grid item xs={12} className={classes.stepBack}>
                <SolidButton
                    size="l"
                    palette="primary"
                    label={localize(Error500LocalizationId.BackButton)}
                    onClick={goBack}
                />
            </Grid>
        </ErrorLayout>
    );
};

export const InternalErrorPage = withLocalization(InternalErrorPageElement, LocalizedComponentName.InternalErrorPage);
