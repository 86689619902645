import { useEffect, useMemo } from "react";
import { Outlet, useMatches, useNavigate } from "react-router-dom";

import axios from "axios";

import { handleErrorApiResponse } from "shared/api/interceptors/globalApiErrorHandler";
import { INITIAL_LOAD_ACTION } from "shared/constants/actions";
import { useLocalize } from "shared/hooks/useLocalize";
import { useRequestContext } from "shared/hooks/useRequest";
import routeNames from "shared/routes/routeNames";
import { useRootStore } from "shared/store/useRootStore";

import { FullscreenLoader } from "components/fullscreenLoader";

let globalResponseInterceptor: number | null = null;

export const RootRouteElement = () => {
    const localize = useLocalize();
    const navigate = useNavigate();

    const matches = useMatches();
    const {
        rootStore,
        rootStore: {
            fetchUserInfo,
            publicLinksStore: { fetchLinks },
        },
    } = useRootStore();

    const { executeRequest, isLoadingAction } = useRequestContext();

    globalResponseInterceptor = useMemo(() => {
        if (globalResponseInterceptor !== null) {
            axios.interceptors.response.eject(globalResponseInterceptor);
        }

        return axios.interceptors.response.use(
            response => response,
            error => handleErrorApiResponse(error, localize, navigate)
        );
    }, [localize, navigate]);

    useEffect(() => {
        const request = async () => {
            await Promise.all([fetchLinks(), fetchUserInfo()]);
            if (
                matches.every(it => it.pathname !== routeNames.LOGOUT && it.pathname !== routeNames.CONSENT) &&
                rootStore.isAuthenticated
            ) {
                document.location = routeNames.PORTAL.HOME;
            }
        };

        executeRequest(request, INITIAL_LOAD_ACTION, []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [executeRequest, fetchUserInfo, fetchLinks, rootStore]);

    if (isLoadingAction(INITIAL_LOAD_ACTION)) {
        return <FullscreenLoader />;
    }

    return <Outlet />;
};
