import { RefObject, useEffect, useMemo, useRef, useState } from "react";

import throttle from "lodash/throttle";

interface UseTextOverflowValues<T> {
    contentRef: RefObject<T>;
    isTextOverflow: boolean;
}

const RESIZE_THROTTLE_DELAY_MS = 1000;

export function useTextOverflow<T extends HTMLElement>(
    cardResizeThrottleDelayMs = RESIZE_THROTTLE_DELAY_MS
): UseTextOverflowValues<T> {
    const contentRef = useRef<T>(null);

    const [isTextOverflow, setIsTextOverflow] = useState(false);

    const onResize = useMemo(
        () =>
            throttle(
                () => {
                    if (!contentRef?.current) {
                        return;
                    }

                    setIsTextOverflow(
                        contentRef.current.scrollWidth > contentRef.current.offsetWidth ||
                            contentRef.current.scrollHeight > contentRef.current.offsetHeight
                    );
                },
                cardResizeThrottleDelayMs,
                { trailing: true }
            ),
        [cardResizeThrottleDelayMs]
    );

    useEffect(() => {
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
    }, [cardResizeThrottleDelayMs, onResize]);

    useEffect(() => {
        onResize();
    });

    return { contentRef, isTextOverflow };
}
