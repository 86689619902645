import { Tooltip as MaterialTooltip, Typography } from "@mui/material";

import {
    LEAVE_TOUCH_DELAY_MS,
    LONG_LEAVE_TOUCH_DELAY_MS,
    NO_DELAY_MS,
    TOUCH_DELAY_MS,
} from "uiKit/containers/tooltip/constants";
import { TooltipProps } from "uiKit/containers/tooltip/interfaces";
import { useTitleStyles, useTooltipStyles } from "uiKit/containers/tooltip/styles";

export const Tooltip = ({
    children,
    title,
    titleVariant = "body2",
    arrow = false,
    placement = "bottom",
    disablePortal = false,
    withoutLeaveTouchDelay = false,
    withoutEnterTouchDelay = false,
    disabled = false,
}: TooltipProps): JSX.Element => {
    const { classes: titleClasses } = useTitleStyles();
    const { classes: tooltipClasses } = useTooltipStyles();
    return disabled ? (
        children
    ) : (
        <MaterialTooltip
            enterTouchDelay={withoutEnterTouchDelay ? NO_DELAY_MS : TOUCH_DELAY_MS}
            leaveTouchDelay={withoutLeaveTouchDelay ? LONG_LEAVE_TOUCH_DELAY_MS : LEAVE_TOUCH_DELAY_MS}
            PopperProps={{ disablePortal }}
            arrow={arrow}
            classes={tooltipClasses}
            title={
                title && (
                    <Typography classes={titleClasses} variant={titleVariant}>
                        {title}
                    </Typography>
                )
            }
            placement={placement}
        >
            {children}
        </MaterialTooltip>
    );
};
