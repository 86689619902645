import { useCallback, useEffect } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";

import { Card, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";

import { observer } from "mobx-react-lite";
import { SolidButton } from "uiKit/buttons/solidButton";
import { TranslucentButton } from "uiKit/buttons/translucentButton";

import { ConsentLocalizationId } from "pages/consent/localization";
import { ConsentStore } from "pages/consent/store";
import { useConsentPageStyles } from "pages/consent/styles";
import { DENY_CONSENT_ACTION, GRANT_CONSENT_ACTION } from "shared/constants/actions";
import { useLocalize } from "shared/hooks/useLocalize";
import { useRequestContext } from "shared/hooks/useRequest";
import { useStore } from "shared/hooks/useStore";
import { LocalizedComponentName } from "shared/localization/enums";
import { withLocalization } from "shared/localization/withLocalization";
import routeNames from "shared/routes/routeNames";

import { FullscreenLoader } from "components/fullscreenLoader";

const ConsentElement = () => {
    const [search] = useSearchParams();
    const returnUrl = search.get("returnUrl") || "";

    const localize = useLocalize();
    const { classes } = useConsentPageStyles();
    const navigate = useNavigate();
    const { executeRequest, isLoadingAction } = useRequestContext();
    const {
        store: { consentDetails, fetchConsentDetails, grantOrDenyConsent },
    } = useStore(ConsentStore);

    useEffect(() => {
        fetchConsentDetails(returnUrl);
    }, [executeRequest, fetchConsentDetails, returnUrl]);

    const onButtonClick = useCallback(
        async (deny: boolean) => {
            const actionName = deny ? DENY_CONSENT_ACTION : GRANT_CONSENT_ACTION;
            const validReturnUrl = await executeRequest(grantOrDenyConsent, actionName, [deny, returnUrl]);
            if (validReturnUrl) {
                window.location.replace(validReturnUrl);
            } else {
                navigate(routeNames.LOGIN.ROOT, { replace: true });
            }
        },
        [executeRequest, grantOrDenyConsent, navigate, returnUrl]
    );

    if (!returnUrl) {
        return <Navigate to={routeNames.ERRORS.NOT_FOUND} />;
    }

    if (!consentDetails) {
        return <FullscreenLoader />;
    }

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <div className={classes.title}>
                    <Typography variant="h4" display="inline">
                        {consentDetails.clientName}&nbsp;
                    </Typography>
                    <Typography variant="body1" display="inline">
                        {localize(ConsentLocalizationId.ClientIntentDescription)}
                    </Typography>
                </div>
                <Card className={classes.scopes}>
                    <Typography variant="h6" className={classes.scopesTitle}>
                        {localize(ConsentLocalizationId.ScopesSectionTitle)}
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableBody>
                                {consentDetails.scopes.map(scope => (
                                    <TableRow key={scope} className={classes.scopesTableRow}>
                                        <TableCell component="th" scope="row">
                                            {scope}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
                <div className={classes.buttons}>
                    <SolidButton
                        label={localize(ConsentLocalizationId.AllowButton)}
                        palette="primary"
                        onClick={() => onButtonClick(false)}
                        isLoading={isLoadingAction(GRANT_CONSENT_ACTION)}
                    />
                    <TranslucentButton
                        label={localize(ConsentLocalizationId.DenyButton)}
                        palette="primary"
                        onClick={() => onButtonClick(true)}
                        isLoading={isLoadingAction(DENY_CONSENT_ACTION)}
                    />
                </div>
            </div>
        </div>
    );
};

export const Consent = withLocalization(observer(ConsentElement), LocalizedComponentName.SignInConsent);
