/**
 * Summary: Verify hybris credentials.
 *
 * Tags: Ui
 */
import { AxiosRequestConfig } from "axios";

import { httpPost } from "shared/api/apiHandler";

export const API_POST_UI_HYBRIS_URL = "api/v1/ui/hybris";

export const apiPostUiHybris = async (
    request?: ApiPostUiHybrisRequest,
    config?: AxiosRequestConfig
): Promise<ApiPostUiHybrisResponse> => {
    const result = await httpPost<ApiPostUiHybrisResponse, ApiPostUiHybrisRequest>(
        "",
        API_POST_UI_HYBRIS_URL,
        request,
        config
    );
    return result.data;
};

/**
 *
 */
export type ApiPostUiHybrisRequest = VerifyHybrisCredentialsRequest;

/**
 * Description: Success
 */
export type ApiPostUiHybrisResponse = void;

interface VerifyHybrisCredentialsRequest {
    userName?: string;
    password?: string;
}
