import { createContext, useContext } from "react";

import { RootStore } from "shared/store/rootStore";

interface RootStoreContextValue {
    rootStore: RootStore;
}

export const RootStoreContext = createContext<RootStoreContextValue>(null as never);

export function useRootStore(): RootStoreContextValue {
    const context = useContext(RootStoreContext);
    if (context === undefined) {
        throw new Error("useRootStore must be within RootStoreContext.Provider");
    }

    return context;
}
