/**
 * Summary: Get consent details.
 *
 * Tags: Ui
 */
import { AxiosRequestConfig } from "axios";

import { httpGet } from "shared/api/apiHandler";

export const API_GET_UI_CONSENT_URL = "api/v1/ui/consent";

export const apiGetUiConsent = async (
    request?: ApiGetUiConsentRequest,
    config?: AxiosRequestConfig
): Promise<ApiGetUiConsentResponse> => {
    const result = await httpGet<ApiGetUiConsentResponse, ApiGetUiConsentRequest>(
        "",
        API_GET_UI_CONSENT_URL,
        request,
        config
    );
    return result.data;
};

/**
 *
 */
export type ApiGetUiConsentRequest = GetConsentDetailsRequest;

/**
 * Description: Success
 */
export type ApiGetUiConsentResponse = GetConsentDetailsResponse;

interface GetConsentDetailsRequest {
    returnUrl?: string; // Return url. Example: /resolution-panel
}

interface GetConsentDetailsResponse {
    clientName: string; // Client name. Example: 3rd party client
    scopes: string[]; // Scopes name.
}
