import React, { FunctionComponent } from "react";
import { NumberFormatValues, NumericFormat } from "react-number-format";

import { InputComponentParams } from "uiKit/inputs/textField/interfaces";

interface IntegerInputProps {
    inputRef: (el: HTMLInputElement) => void;
    onChange: (event: React.ChangeEvent) => void;
    name: string;
}

const IntegerInput: FunctionComponent<IntegerInputProps> = (props: IntegerInputProps) => {
    const { inputRef: setInputRefInNumberFormat, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={setInputRefInNumberFormat}
            allowNegative={false}
            isAllowed={(values: NumberFormatValues) =>
                values.floatValue === undefined || values.floatValue < Number.MAX_SAFE_INTEGER
            }
        />
    );
};

export const IntegerInputComponentParams: InputComponentParams = {
    inputComponent: IntegerInput,
};
