import { makeStyles } from "tss-react/mui";

import { TxtColor, whiteColor } from "shared/theme/defaultTheme";

export const useFooterStyles = makeStyles()(() => ({
    navigation: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        margin: "1.25rem 0",
        backgroundColor: whiteColor,
        "& a": {
            margin: "0.625rem",
            color: TxtColor.Txt3,
        },
    },
}));
