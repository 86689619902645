import { action, makeObservable, observable, runInAction } from "mobx";

import { ApiPostUiLogoutResponse, apiPostUiLogout } from "shared/api/auth/ui/apiPostUiLogout";
import { RootStore } from "shared/store/rootStore";

export class SignOutStore {
    @observable public logoutId?: string;
    @observable public logoutDetails?: ApiPostUiLogoutResponse;
    @observable private readonly rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @action.bound
    public async logout(logoutId?: string): Promise<void> {
        if (!logoutId || this.logoutId === logoutId) {
            return;
        }

        this.logoutId = logoutId;
        const response = await apiPostUiLogout({
            logoutId: logoutId,
        });
        await this.rootStore.fetchUserInfo();

        runInAction(() => {
            this.logoutDetails = response;
        });
    }
}
