import { intervalToDuration } from "date-fns";
import { orderBy } from "lodash";
import { action, computed, makeObservable, observable, runInAction } from "mobx";

import { AuthMethod, LoginStep } from "pages/login/constants/enums";
import { AccountType, LoginState } from "pages/login/interfaces";
import { EnterCredentialsFormValues } from "pages/login/steps/enterCredentials/schema";
import { apiGetUiLoginOptions } from "shared/api/auth/ui/apiGetUiLoginOptions";
import { apiPostUiEmail } from "shared/api/auth/ui/apiPostUiEmail";
import { apiPostUiHybris } from "shared/api/auth/ui/apiPostUiHybris";
import { ApiPostUiLoginResponse, apiPostUiLogin } from "shared/api/auth/ui/apiPostUiLogin";
import { apiPostUiRequestLoginCode } from "shared/api/auth/ui/apiPostUiRequestLoginCode";
import { CODE_GENERATION_UNAVAILABLE, USER_IS_LOCKED } from "shared/api/constants/errors";
import { BadRequestErrorItem } from "shared/api/errors";
import { isAxios400Error } from "shared/api/utils";
import { REDIRECT_TIMEOUT_MS } from "shared/constants";
import routeNames from "shared/routes/routeNames";

const ACR_VALUES_PARAM = "acr_values";
const ACCOUNT_ID_HINT_ACR_VALUE_PREFIX = "account_id:";

const DEFAULT_LOGIN_STATE: LoginState = {
    activeStep: LoginStep.EnterEmail,
    email: "",
    verificationEmail: "",
    userName: "",
    password: "",
    firstName: "",
    lastName: "",
    phone: "",
    authCode: "",
    authToken: undefined,
    selectedAuthMethod: AuthMethod.Email,
    accounts: [],
};

export class SignInStore {
    @observable public loginState: LoginState = DEFAULT_LOGIN_STATE;
    @observable public errors: BadRequestErrorItem[] = [];
    @observable public returnUrl?: string;
    @observable public accountIdHint?: number;
    @observable public isLocked = false;
    @observable public resendLoginCodeTimer?: Duration;
    @observable public emailsToLogin = new Map<string, number>();

    private timerIntervalId?: NodeJS.Timeout;

    constructor() {
        makeObservable(this);
    }

    @computed
    public get credentials(): EnterCredentialsFormValues {
        const { userName, password } = this.loginState;
        return { userName, password };
    }

    @computed
    public get hasErrors(): boolean {
        return this.errors.length > 0;
    }

    @action.bound
    public setReturnUrl(pathname: string): void {
        this.returnUrl = pathname;
        const url = new URL(pathname, document.location.origin);
        const acrValues = url.searchParams.get(ACR_VALUES_PARAM);
        if (acrValues) {
            const parsedAcrValues = acrValues.split(" ");
            const accountIdAcrValue = parsedAcrValues.find(it => it.startsWith(ACCOUNT_ID_HINT_ACR_VALUE_PREFIX));
            const accountId = accountIdAcrValue?.substring(ACCOUNT_ID_HINT_ACR_VALUE_PREFIX.length);
            this.accountIdHint = accountId ? Number(accountId) : undefined;
        }
    }

    @action.bound
    public setStep(step: LoginStep): void {
        this.loginState.activeStep = step;
    }

    @action.bound
    public setSelectedAuthMethod(index: number): void {
        this.loginState.selectedAuthMethod = index;
    }

    @action.bound
    public clearErrors(): void {
        this.errors = [];
        this.setIsLocked(false);
    }

    @action.bound
    public updateLoginState(loginState: Partial<LoginState>): void {
        this.loginState = { ...this.loginState, ...loginState };
    }

    @action.bound
    public async getLoginOptions(email: string): Promise<void> {
        try {
            this.clearErrors();
            const startLoginModel = await apiGetUiLoginOptions({ email });
            this.setIsLocked(!!startLoginModel.isLocked);

            runInAction(() => {
                this.loginState = {
                    ...this.loginState,
                    email,
                    phone: startLoginModel.phone || "",
                };
            });
            if (startLoginModel.isLocked) {
                return;
            } else if (this.loginState.phone && this.loginState.phone !== "") {
                this.setStep(LoginStep.ChooseAuthenticationMethod);
            } else {
                this.setSelectedAuthMethod(AuthMethod.Email);
                await this.requestLoginCode();
            }
        } catch (error) {
            this.handleError(error);
        }
    }

    @action.bound
    public async requestLoginCode(): Promise<void> {
        try {
            this.clearErrors();

            this.runResendCodeTimer();

            await apiPostUiRequestLoginCode({
                email: this.loginState.email,
                authMethod: this.loginState.selectedAuthMethod,
                returnUrl: this.returnUrl,
            });

            if (this.loginState.selectedAuthMethod == AuthMethod.Email) {
                this.setStep(LoginStep.CheckEmailInbox);
            } else {
                this.setStep(LoginStep.EnterPhoneCode);
            }
        } catch (error) {
            if (!isAxios400Error(error)) {
                return;
            }

            if (
                error.response.data.errors.some(it => it.code === CODE_GENERATION_UNAVAILABLE) &&
                this.loginState.selectedAuthMethod == AuthMethod.Sms
            ) {
                this.setStep(LoginStep.EnterPhoneCode);
            } else {
                this.handleError(error);
            }
        }
    }

    @action.bound
    public async loginByToken(token: string): Promise<void> {
        const response = await apiPostUiLogin({
            authMethod: AuthMethod.Email,
            token,
            returnUrl: this.returnUrl,
        });

        runInAction(() => {
            this.loginState.authToken = token;
        });

        await this.handleLoginResponse(response);
    }

    @action.bound
    public async loginByCode(code: string): Promise<void> {
        this.clearErrors();
        try {
            const response = await apiPostUiLogin({
                authMethod: AuthMethod.Sms,
                email: this.loginState.email,
                code,
                returnUrl: this.returnUrl,
            });

            runInAction(() => {
                this.loginState.authCode = code;
            });

            await this.handleLoginResponse(response);
        } catch (error) {
            if (!isAxios400Error(error)) {
                return;
            }

            if (error.response.data.errors.some(it => it.code === USER_IS_LOCKED || it.isLocked)) {
                this.setIsLocked(true);
            }
            this.handleError(error);
        }
    }

    @action.bound
    public async continueLogin(accountId: number): Promise<void> {
        this.clearErrors();
        const response = await apiPostUiLogin({
            authMethod: this.loginState.selectedAuthMethod,
            email: this.loginState.email,
            code: this.loginState.authCode,
            token: this.loginState.authToken,
            returnUrl: this.returnUrl,
            accountId,
        });

        await this.handleLoginResponse(response);
    }

    @action.bound
    public async verifyEmail(email: string): Promise<void> {
        try {
            this.clearErrors();

            const { userName, password } = this.loginState;

            await apiPostUiEmail({ userName, password, email, returnUrl: this.returnUrl });

            runInAction(() => {
                this.loginState.verificationEmail = email;
            });

            this.setStep(LoginStep.CheckVerificationEmailInbox);
        } catch (error) {
            this.handleError(error);
        }
    }

    @action.bound
    public async validateCredentials(credentials: EnterCredentialsFormValues): Promise<void> {
        try {
            this.clearErrors();
            await apiPostUiHybris(credentials);
            this.setStep(LoginStep.VerifyEmail);

            runInAction(() => {
                const { userName, password } = credentials;
                if (this.loginState.userName !== userName || this.loginState.password !== password) {
                    this.loginState.verificationEmail = "";
                    this.loginState.password = password;
                    this.loginState.userName = userName;
                }
            });
        } catch (error) {
            this.handleError(error);
        }
    }

    @action.bound
    public setIsLocked(isLocked: boolean): void {
        this.isLocked = isLocked;
    }

    @action.bound
    public goBack(): void {
        if (this.loginState.activeStep === LoginStep.VerifyEmail) {
            this.setStep(LoginStep.EnterCredentials);
        } else if (this.loginState.activeStep === LoginStep.EnterPhoneCode) {
            this.setStep(LoginStep.ChooseAuthenticationMethod);
        } else if (this.loginState.activeStep === LoginStep.CheckEmailInbox && this.loginState.phone) {
            this.setStep(LoginStep.ChooseAuthenticationMethod);
        } else if (this.loginState.activeStep === LoginStep.CheckEmailInbox && !this.loginState.phone) {
            this.setStep(LoginStep.EnterEmail);
        } else if (this.loginState.activeStep === LoginStep.CheckVerificationEmailInbox) {
            this.setStep(LoginStep.VerifyEmail);
        } else {
            this.setStep(LoginStep.EnterEmail);
        }
        this.clearErrors();
    }

    @action.bound
    public stopTimer(): void {
        if (this.timerIntervalId === undefined) {
            return;
        }

        clearInterval(this.timerIntervalId);
        this.timerIntervalId = undefined;
        this.resendLoginCodeTimer = undefined;
    }

    @action.bound
    private setCurrentEmailToList(): void {
        const now = Date.now();

        const resendCodeEndDate = now + window.APP_SETTINGS.resendCodeDurationSeconds * 1000;
        this.emailsToLogin.set(this.loginState.email, resendCodeEndDate);
    }

    @action.bound
    private runResendCodeTimer(): void {
        if (this.loginState.selectedAuthMethod !== AuthMethod.Sms) {
            return;
        }
        const isEmailWithValidTimeInList =
            this.emailsToLogin.has(this.loginState.email) &&
            this.emailsToLogin.get(this.loginState.email)! - Date.now() > 0;

        if (!isEmailWithValidTimeInList) {
            this.setCurrentEmailToList();
        }
        this.startResendCodeTimer();
    }

    @action.bound
    private setLeftTimeToResendCode(endDate: number): void {
        const remainingTime = endDate - Date.now();
        if (!endDate || !remainingTime || remainingTime <= 0 || this.isLocked) {
            this.emailsToLogin.delete(this.loginState.email);
            this.stopTimer();
            return;
        }
        this.resendLoginCodeTimer = intervalToDuration({ start: 0, end: Math.ceil(remainingTime / 10) * 10 });
    }

    @action.bound
    private startResendCodeTimer(): void {
        this.stopTimer();
        const endDate = this.emailsToLogin.get(this.loginState.email) || 0;
        this.setLeftTimeToResendCode(endDate);
        this.timerIntervalId = setInterval(() => this.setLeftTimeToResendCode(endDate), 1000);
    }

    @action.bound
    private async handleLoginResponse(response: ApiPostUiLoginResponse): Promise<void> {
        if (response.isAccountSelectionRequired) {
            if (this.accountIdHint && response.accounts?.some(it => it.accountId === this.accountIdHint)) {
                return this.continueLogin(this.accountIdHint);
            }

            runInAction(() => {
                this.loginState.firstName = response.firstName;
                this.loginState.lastName = response.lastName;
                const accountTypeOrder = [
                    AccountType.Agent,
                    AccountType.PrivateLandlord,
                    AccountType.CorporateLandlord,
                    AccountType.Tenant,
                ];

                const itemPositions = new Map(accountTypeOrder.map((it, index) => [it, index]));
                this.loginState.accounts = orderBy(response.accounts ?? [], [
                    account => itemPositions.get(account.accountType!.id),
                    account => account.accountIndex,
                ]);

                this.setStep(LoginStep.ChooseAccount);
            });
        } else {
            if (response.validReturnUrl) {
                window.location.replace(response.validReturnUrl);
            } else {
                window.location.replace(routeNames.PORTAL.HOME);
            }
            await new Promise(resolve => setTimeout(resolve, REDIRECT_TIMEOUT_MS));
        }
    }

    @action.bound
    private handleError(error: unknown): void {
        if (isAxios400Error(error)) {
            const newErrors = error.response.data.errors;
            runInAction(() => {
                this.errors = newErrors;
            });
        }
    }
}
