/**
 * Summary: Request login code via Email or SMS channel for specified user.
 *
 * Tags: Ui
 */
import { AxiosRequestConfig } from "axios";

import { httpPost } from "shared/api/apiHandler";

export const API_POST_UI_REQUEST_LOGIN_CODE_URL = "api/v1/ui/request-login-code";

export const apiPostUiRequestLoginCode = async (
    request?: ApiPostUiRequestLoginCodeRequest,
    config?: AxiosRequestConfig
): Promise<ApiPostUiRequestLoginCodeResponse> => {
    const result = await httpPost<ApiPostUiRequestLoginCodeResponse, ApiPostUiRequestLoginCodeRequest>(
        "",
        API_POST_UI_REQUEST_LOGIN_CODE_URL,
        request,
        config
    );
    return result.data;
};

/**
 *
 */
export type ApiPostUiRequestLoginCodeRequest = RequestLoginCodeRequest;

/**
 * Description: Success
 */
export type ApiPostUiRequestLoginCodeResponse = void;

interface RequestLoginCodeRequest {
    authMethod: 1 | 2; // Auth method. Example: 1
    email?: string; // User's email. Example: agent@mail.com
    returnUrl?: string; // Return link. Example: /resolution-panel
}
