import React, { useMemo } from "react";
import { SubmitHandler } from "react-hook-form";

import { Typography } from "@mui/material";

import { observer } from "mobx-react-lite";
import { Form } from "uiKit/containers/form";
import { Link } from "uiKit/links";

import { ErrorNotification } from "pages/login/errorNotification";
import { NavigationButtons } from "pages/login/navigationButtons";
import { LoginStepContent } from "pages/login/stepContent";
import { FormPhoneCode } from "pages/login/steps/enterPhoneCode/form";
import { PhoneCodeLocalizationId } from "pages/login/steps/enterPhoneCode/localization";
import { PhoneCodeFormValues, createPhoneCodeSchema } from "pages/login/steps/enterPhoneCode/schema";
import { usePhoneCodeStyles } from "pages/login/steps/enterPhoneCode/styles";
import { SignInStore } from "pages/login/store";
import { formatTimer } from "pages/login/utils";
import { LOGIN_ACTION, RESEND_CODE_ACTION } from "shared/constants/actions";
import { useLocalize } from "shared/hooks/useLocalize";
import { useRequestContext } from "shared/hooks/useRequest";
import { useStore } from "shared/hooks/useStore";

const EnterPhoneCodeElement = (): React.ReactElement => {
    const localize = useLocalize();
    const { isLoadingAction, executeRequest } = useRequestContext();

    const {
        store: { loginByCode, requestLoginCode, loginState, resendLoginCodeTimer, hasErrors, isLocked },
    } = useStore(SignInStore);

    const { classes } = usePhoneCodeStyles({ isDisabled: !!resendLoginCodeTimer });

    const schema = useMemo(() => createPhoneCodeSchema(localize), [localize]);

    const timer = useMemo(() => {
        if (!resendLoginCodeTimer) {
            return null;
        }
        return formatTimer(resendLoginCodeTimer);
    }, [resendLoginCodeTimer]);

    const navigationButtons = useMemo(() => {
        return (
            <NavigationButtons
                backButtonLabel={localize(PhoneCodeLocalizationId.BackButton)}
                submitButtonLabel={localize(PhoneCodeLocalizationId.LoginButton)}
            />
        );
    }, [localize]);

    const onSubmit: SubmitHandler<PhoneCodeFormValues> = async data => {
        await executeRequest(loginByCode, LOGIN_ACTION, [data.confirmationCode]);
    };

    const resendCode = async () => {
        await executeRequest(requestLoginCode, RESEND_CODE_ACTION, []);
    };

    const isErrorNoteVisible = isLocked || hasErrors;

    return (
        <LoginStepContent>
            <Typography variant="h1">{localize(PhoneCodeLocalizationId.GetPassword)}</Typography>
            {isErrorNoteVisible && <ErrorNotification />}
            {!isLocked && (
                <>
                    <Typography className={!isErrorNoteVisible ? classes.phoneVerificationDescription : undefined}>
                        {localize(PhoneCodeLocalizationId.EnterVerificationCode)} {loginState.phone}
                    </Typography>
                    <Form<PhoneCodeFormValues> onHandleSubmit={onSubmit} schema={schema} className={classes.form}>
                        <div className={classes.content}>
                            <div className={classes.phoneCodeContainer}>
                                <FormPhoneCode />
                            </div>
                            <div className={classes.resendCodeContainer}>
                                <Typography className={classes.resendCodeTypography} variant="body1">
                                    {localize(PhoneCodeLocalizationId.DidntGetAMessage)}
                                    <Link
                                        disabled={isLoadingAction(RESEND_CODE_ACTION) || !!resendLoginCodeTimer}
                                        label={localize(PhoneCodeLocalizationId.ResendCode)}
                                        onClick={resendCode}
                                        typographyVariant="body1"
                                        wrapperClassName={classes.link}
                                    />
                                    {!!resendLoginCodeTimer &&
                                        localize(PhoneCodeLocalizationId.ResendCodeLinkTimer, {
                                            Count: timer,
                                        })}
                                </Typography>
                            </div>
                            {navigationButtons}
                        </div>
                    </Form>
                </>
            )}
            {isLocked && navigationButtons}
        </LoginStepContent>
    );
};

export const EnterPhoneCode = observer(EnterPhoneCodeElement);
