import { ElementWidthControl } from "uiKit/interfaces";

export const getContainerWidthsBySize = (size: string): ElementWidthControl => {
    if (size === "full") {
        return { width: "100%", maxWidth: "100%", minWidth: "100%" };
    }
    if (size === "auto") {
        return { width: "100%", maxWidth: "752px", minWidth: "168px" };
    }
    if (size === "small") {
        return { width: "168px", maxWidth: "168px", minWidth: "168px" };
    }
    if (size === "medium") {
        return { width: "460px", maxWidth: "460px", minWidth: "460px" };
    }
    if (size === "big") {
        return { width: "752px", maxWidth: "752px", minWidth: "752px" };
    }
    return { width: "460px", maxWidth: "460px", minWidth: "460px" };
};
