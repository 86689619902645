export enum ValidationLocalizationId {
    MaximumLengthValidatorKey = "validation-messages.MaximumLengthValidator",
    MinimumLengthValidatorKey = "validation-messages.MinimumLengthValidator",
    RequiredLengthValidatorKey = "validation-messages.RequiredLengthValidator",
    RequiredTwoLengthValidatorKey = "validation-messages.RequiredTwoLengthValidator",
    NotEmptyValidatorKey = "validation-messages.NotEmptyValidator",
    InvalidEmailAddressKey = "validation-messages.InvalidEmailAddress",
    GreaterThanValidatorKey = "validation-messages.GreaterThanValidator",
    GreaterThanOrEqualValidatorKey = "validation-messages.GreaterThanOrEqualValidator",
    LessThanOrEqualValidatorKey = "validation-messages.LessThanOrEqualValidator",
    MoneyLessThanOrEqualValidatorKey = "validation-messages.MoneyLessThanOrEqualValidator",
    MoneyLessThanValidatorKey = "validation-messages.MoneyLessThanValidator",
    ExpiredCodeValidatorKey = "validation-messages.ExpiredCodeValidator",
    PhoneNumberValidator = "validation-messages.PhoneNumberValidator",
    PolicyAndTermsAcceptValidator = "validation-messages.PolicyAndTermsAccept",

    RegularExpressionValidator = "validation-messages.RegularExpressionValidator",
    LessThanValidatorKey = "validation-messages.LessThanValidator",
    AgeValidator = "validation-messages.AgeValidator",
    EntityShouldBeUnique = "validation-messages.EntityShouldBeUnique",
    MinValueValidator = "validation-messages.MinValueValidator",
    MaxRolesCountExceeded = "validation-messages.MaxRolesCountExceeded",
    CannotBeMoreThanNMonthsFutureKey = "validation-messages.CannotBeMoreThanNMonthsFuture",
    EndOfTenancyLeavingDateKey = "validation-messages.EndOfTenancyLeavingDate",
    LeavingDateMustBeTodayOrInThePast = "validation-messages.LeavingDateMustBeTodayOrInThePast",
    MaxLessThanMin = "validation-messages.MaxLessThanMin",
    FieldIsRequiredWithCheckboxAlternative = "validation-messages.FieldIsRequiredWithCheckboxAlternative",
    SomeReleaseRequestDocumentsAreMissing = "validation-messages.SomeReleaseRequestDocumentsAreMissing",

    TenantAddressIsEqualToPropertyAddress = "validation-messages.TenantAddressIsEqualToPropertyAddress",
    InterestedPartyAddressIsEqualToPropertyAddress = "validation-messages.InterestedPartyAddressIsEqualToPropertyAddress",
    ValueGreaterThanDepositOutstandingAmount = "validation-messages.ValueGreaterThanDepositOutstandingAmount",
    RoutingNumberShouldStartWithCorrectNumber = "validation-messages.RoutingNumberShouldStartWithCorrectNumber",

    AtLeastOneTenantMustRemainInTheTenancy = "validation-messages.AtLeastOneTenantMustRemainInTheTenancy",
    DateMustBeLessOrEqualThanTenancyEndDate = "validation-messages.DateMustBeLessOrEqualThanTenancyEndDate",
    DateMustBeGreaterThanOrEqualToTenancyStartDate = "validation-messages.DateMustBeGreaterThanOrEqualToTenancyStartDate",
    DateMustBeGreaterThanOrEqualToOneYearInThePastFromNow = "validation-messages.DateMustBeGreaterThanOrEqualToOneYearInThePastFromNow",

    DateMustBeEarlierThanOrEqualToProtectionStartDate = "validation-messages.DateMustBeEarlierThanOrEqualToProtectionStartDate",
    DateMustBeLaterThanOrEqualToProtectionStartDate = "validation-messages.DateMustBeLaterThanOrEqualToProtectionStartDate",
    DateMustBeEarlierThanOrEqualToTwoYearsFromNow = "validation-messages.DateMustBeEarlierThanOrEqualToTwoYearsFromNow",

    FileSizeValidator = "validation-messages.FileSizeValidator",
}
