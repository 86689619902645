import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { FormTextField } from "uiKit/inputs/textField";

import { EnterEmailLocalizationId } from "pages/login/steps/enterEmail/localization";
import { SignInStore } from "pages/login/store";
import { useLocalize } from "shared/hooks/useLocalize";
import { useStore } from "shared/hooks/useStore";

export const FormEnterEmail = () => {
    const localize = useLocalize();

    const {
        store: { setIsLocked },
    } = useStore(SignInStore);

    const {
        control,
        watch,
        formState: { errors },
    } = useFormContext();

    useEffect(() => {
        watch(() => {
            setIsLocked(false);
        });
    }, [setIsLocked, watch]);

    return (
        <FormTextField
            name="email"
            label={localize(EnterEmailLocalizationId.Email)}
            errors={errors}
            control={control}
        />
    );
};
