import { MessageValues } from "shared/hooks/useLocalize";
import { MessageValue } from "shared/validation/validation.messageValues";

export function getValidatorValues(propertyName: string): MessageValues {
    return {
        [MessageValue.PropertyName]: propertyName,
    };
}

export function getMaximumLengthValidatorValues(
    propertyName: string,
    maxLength: number,
    totalLength: number
): MessageValues {
    return {
        [MessageValue.PropertyName]: propertyName,
        [MessageValue.MaxLength]: maxLength,
        [MessageValue.TotalLength]: totalLength,
    };
}
