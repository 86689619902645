import { ObjectSchema, object } from "yup";

import { PhoneCodeLocalizationId } from "pages/login/steps/enterPhoneCode/localization";
import { LocalizeFunction } from "shared/hooks/useLocalize";
import { smsCodeValidator } from "shared/validation/validation.number";

export interface PhoneCodeFormValues {
    confirmationCode: string;
}

export const createPhoneCodeSchema = (localize: LocalizeFunction): ObjectSchema<PhoneCodeFormValues> =>
    object().shape({
        confirmationCode: smsCodeValidator(localize, localize(PhoneCodeLocalizationId.PhoneCodeValidationFieldName)),
    });
