import { makeStyles } from "tss-react/mui";
import { ElementWidthControl } from "uiKit//interfaces";

import { TxtColor, applicationPalettes, tabletBreakpoint, whiteColor } from "shared/theme/defaultTheme";

export const useNoteStyles = makeStyles<ElementWidthControl>()((theme, props) => ({
    root: {
        boxSizing: "border-box",
        padding: theme.spacing(1.5, 2.25),
        borderRadius: ".5rem",
        width: "100%",
        maxWidth: "100%",
        minWidth: "100%",
        flexShrink: 0,
        "&.info": {
            background: applicationPalettes.primary[1],
        },
        "&.error": {
            backgroundColor: applicationPalettes.red[1],
        },
        "&.critical": {
            backgroundColor: applicationPalettes.red[500],
            borderRadius: 0,
            padding: theme.spacing(1, 2),
            alignItems: "center",
            "& *": {
                color: whiteColor,
            },
            "& svg": {
                marginTop: 0,
            },
        },
        "&.warning": {
            backgroundColor: applicationPalettes.red[1],
        },
        "&.success": {
            background: applicationPalettes.green[1],
        },
        [theme.breakpoints.up(tabletBreakpoint)]: {
            width: props.width,
            maxWidth: props.maxWidth,
            minWidth: props.minWidth,
        },
    },
    container: {
        display: "flex",
    },
}));

export const useNoteIconStyles = makeStyles()(theme => ({
    root: {
        marginTop: theme.spacing(0.25),
        marginRight: theme.spacing(1.5),
        fontSize: "20px",
        "&.info": {
            color: applicationPalettes.primary[500],
        },
        "&.error": {
            color: applicationPalettes.red[500],
        },
        "&.warning": {
            color: applicationPalettes.orange[500],
        },
        "&.success": {
            color: applicationPalettes.green[500],
        },
    },
}));

export const useContentStyles = makeStyles()(() => ({
    root: {
        display: "flex",
        flexDirection: "column",
    },
    text: {
        color: TxtColor.Txt2,
    },
}));
