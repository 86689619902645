import { Typography } from "@mui/material";

import clsx from "clsx";
import { useContentStyles } from "uiKit/containers/note/styles";

interface Props {
    title?: React.ReactNode;
    text?: React.ReactNode;
}

export const NoteText = ({ title, text }: Props) => {
    const { classes } = useContentStyles();

    return (
        <div className={classes.root}>
            {!!title && <Typography variant="subtitle2">{title}</Typography>}
            {!!text && (
                <Typography variant="body2" className={clsx({ [classes.text]: !!title })}>
                    {text}
                </Typography>
            )}
        </div>
    );
};
