import { Typography } from "@mui/material";

import { Link } from "uiKit/links";

import { SignUpStepHeaderLocalizationId } from "pages/login/signUpStepHeader/localization";
import { useSignUpStepHeaderStyles } from "pages/login/signUpStepHeader/styles";
import { useLocalize } from "shared/hooks/useLocalize";
import routeNames from "shared/routes/routeNames";

export const SignUpStepHeader = () => {
    const { classes } = useSignUpStepHeaderStyles();

    const localize = useLocalize();

    return (
        <div className={classes.heading}>
            <Typography variant="body2" className={classes.headingTypo}>
                {localize(SignUpStepHeaderLocalizationId.DontHaveAnAccount)}&nbsp;
                <Link
                    link={routeNames.PORTAL.SIGN_UP}
                    label={localize(SignUpStepHeaderLocalizationId.SignUpLink)}
                    typographyVariant="body2"
                />
            </Typography>
        </div>
    );
};
