import { makeStyles } from "tss-react/mui";
import {
    ButtonStylesProps,
    buttonMaxWidth,
    getButtonBorderRadius,
    getButtonHeight,
    getButtonMinWidth,
    getButtonPaddings,
    getButtonWidth,
    getColorFromPalette,
    getShadowFromPalette,
} from "uiKit/buttons/styles";

import { TxtColor, applicationPalettes, tabletBreakpoint, whiteColor } from "shared/theme/defaultTheme";

export const useSolidButtonStyles = makeStyles<ButtonStylesProps>()((theme, props) => ({
    root: {
        height: getButtonHeight(props),
        borderRadius: getButtonBorderRadius(props),
        padding: getButtonPaddings(props),
        width: "100%",
        [theme.breakpoints.up(tabletBreakpoint)]: {
            width: getButtonWidth(props),
            minWidth: getButtonMinWidth(props),
            maxWidth: buttonMaxWidth,
        },
    },
    contained: {
        color: TxtColor.Txt8,
        boxShadow: getShadowFromPalette(props, 4),
        backgroundColor: getColorFromPalette(props, 500),

        [theme.breakpoints.up(tabletBreakpoint)]: {
            boxShadow: getShadowFromPalette(props, 24),
        },
        "@media (hover: hover)": {
            "&:hover": {
                backgroundColor: getColorFromPalette(props, 600),
                boxShadow: "none",
            },
        },
        "@media (hover: none)": {
            "&:hover": {
                backgroundColor: getColorFromPalette(props, 500),
                boxShadow: "none",
            },
        },
        "&:active": {
            backgroundColor: getColorFromPalette(props, 500),
            boxShadow: "none",
        },
        "&.Mui-disabled": {
            backgroundColor: getColorFromPalette(props, 50),
            boxShadow: "none",
            color: whiteColor,
        },
    },
    focusVisible: {
        border: `3px solid ${applicationPalettes.default[600]}`,
    },
    label: {
        color: TxtColor.Txt8,
    },
    disabled: {
        backgroundColor: getColorFromPalette(props, 50),
        boxShadow: "none",
    },
}));
