export enum LoginExpiredLocalizationId {
    ErrorLoginTitle = "registration-link-expired.error_login_title",
    ErrorLoginDescription = "registration-link-expired.error_login_description",
    Image = "registration-link-expired.error_image",
    ResendLinkButton = "registration-link-expired.resend_link_button",
    ResendToastMessage = "registration-link-expired.resend_toast_message",
}

export enum EmailVerificationLocalizationId {
    ErrorLoginTitle = "registration-link-expired.error_verification_title",
    ErrorLoginDescription = "registration-link-expired.error_verification_description",
    Image = "registration-link-expired.error_image",
    ResendLinkButton = "registration-link-expired.resend_link_button",
    ResendToastMessage = "registration-link-expired.resend_toast__message",
}
