import { action, makeObservable, observable, runInAction } from "mobx";

import { ApiGetUiConsentResponse, apiGetUiConsent } from "shared/api/auth/ui/apiGetUiConsent";
import { apiPutUiConsent } from "shared/api/auth/ui/apiPutUiConsent";

export class ConsentStore {
    @observable public consentDetails?: ApiGetUiConsentResponse;

    constructor() {
        makeObservable(this);
    }

    @action.bound
    public async fetchConsentDetails(returnUrl: string): Promise<void> {
        const response = await apiGetUiConsent({
            returnUrl,
        });

        runInAction(() => {
            this.consentDetails = response;
        });
    }

    @action.bound
    public async grantOrDenyConsent(deny: boolean, returnUrl: string): Promise<string | undefined> {
        const response = await apiPutUiConsent({
            deny,
            returnUrl,
        });
        return response.validReturnUrl;
    }
}
