import React, { useCallback } from "react";

import { Typography } from "@mui/material";

import { observer } from "mobx-react-lite";
import { showSuccessToast } from "uiKit/containers/toast/utils";
import { Link } from "uiKit/links";

import { LoginStep } from "pages/login/constants/enums";
import { NavigationButtons } from "pages/login/navigationButtons";
import { LoginStepContent } from "pages/login/stepContent";
import { CheckInboxLocalizationId } from "pages/login/steps/checkInbox/localization";
import { useCheckInboxStyles } from "pages/login/steps/checkInbox/styles";
import { SignInStore } from "pages/login/store";
import { useLocalize } from "shared/hooks/useLocalize";
import { useRequestContext } from "shared/hooks/useRequest";
import { useStore } from "shared/hooks/useStore";

const CheckInboxElement = (): React.ReactElement => {
    const localize = useLocalize();
    const { isLoading, executeRequest } = useRequestContext();

    const {
        store: {
            requestLoginCode,
            verifyEmail,
            loginState: { email, verificationEmail, activeStep },
        },
    } = useStore(SignInStore);

    const { classes } = useCheckInboxStyles();

    const onResendEmail = useCallback(async () => {
        if (isLoading) {
            return;
        }
        if (activeStep === LoginStep.CheckEmailInbox) {
            await executeRequest(requestLoginCode, "", []);
        } else {
            await executeRequest(verifyEmail, "", [verificationEmail]);
        }
        showSuccessToast({ message: localize(CheckInboxLocalizationId.EmailHasBeenSent) });
    }, [isLoading, activeStep, localize, executeRequest, requestLoginCode, verifyEmail, verificationEmail]);

    const emailToResend = activeStep === LoginStep.CheckEmailInbox ? email : verificationEmail;

    return (
        <LoginStepContent>
            <Typography variant="h1">{localize(CheckInboxLocalizationId.CheckYourInbox)}</Typography>
            <div className={classes.disclaimer}>
                <Typography variant="body1" className={classes.magicLinkText}>
                    {localize(CheckInboxLocalizationId.SentEmailParagraph)} {emailToResend}
                    <br />
                    {localize(CheckInboxLocalizationId.MagicLinkParagraph)}
                </Typography>
            </div>
            <div className={classes.hint}>
                <Typography variant="body1" className={classes.magicLinkText}>
                    {localize(CheckInboxLocalizationId.DidntSeeTheEmail)}{" "}
                    <Link
                        disabled={isLoading}
                        label={localize(CheckInboxLocalizationId.ResendEmailLink)}
                        onClick={onResendEmail}
                        typographyVariant="body1"
                    />
                </Typography>
            </div>
            <div className={classes.backButton}>
                <NavigationButtons backButtonLabel={localize(CheckInboxLocalizationId.BackButton)} />
            </div>
        </LoginStepContent>
    );
};

export const CheckInbox = observer(CheckInboxElement);
