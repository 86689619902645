import { StringSchema, string } from "yup";

import { LocalizeFunction } from "shared/hooks/useLocalize";
import { DEFAULT_INPUT_MAX_LENGTH, EMAIL_FORMAT } from "shared/validation/constants";
import { ValidationLocalizationId } from "shared/validation/validation.localization";
import { getMaximumLengthValidatorValues, getValidatorValues } from "shared/validation/validation.valuesGetters";

export const requiredEmailValidator = (
    localize: LocalizeFunction,
    propertyName: string,
    maxLength: number = DEFAULT_INPUT_MAX_LENGTH
): StringSchema<string> => {
    return string()
        .required(localize(ValidationLocalizationId.NotEmptyValidatorKey, getValidatorValues(propertyName)))
        .max(maxLength, obj =>
            localize(
                ValidationLocalizationId.MaximumLengthValidatorKey,
                getMaximumLengthValidatorValues(propertyName, maxLength, obj.value.length)
            )
        )
        .matches(EMAIL_FORMAT, localize(ValidationLocalizationId.InvalidEmailAddressKey));
};
