import { get, useFormContext } from "react-hook-form";

import { Typography } from "@mui/material";

import { FormInputErrorProps } from "uiKit/inputs/interfaces";

import { applicationPalettes } from "shared/theme/defaultTheme";

export interface FormErrorProps extends FormInputErrorProps {
    name: string;
}

export const FieldError = ({ errors, name }: FormErrorProps) => {
    const methods = useFormContext();
    const error = get(errors || methods.formState.errors, name);
    if (!error) {
        return null;
    }

    const { message } = error;

    return (
        <Typography variant="caption" color={applicationPalettes.red[500]}>
            {message}
        </Typography>
    );
};
