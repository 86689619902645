export enum PhoneCodeLocalizationId {
    GetPassword = "sign-in-phone-code.get_password_title",
    EnterVerificationCode = "sign-in-phone-code.enter_verification_code_paragraph",
    DidntGetAMessage = "sign-in-phone-code.didnt_get_a_message_paragraph",
    ResendCode = "sign-in-phone-code.resend_code_link",
    ResendCodeLinkTimer = "sign-in-phone-code.resend_code_link_timer",
    LoginButton = "sign-in-phone-code.login_button",
    BackButton = "sign-in-phone-code.back_button",
    StepPicture = "sign-in-phone-code.step_picture",
    PhoneCodeValidationFieldName = "sign-in-phone-code.phone_code_validation_field_name",
    SMSCodeHasBeenSent = "notifications.sms_code_has_been_sent",
}
