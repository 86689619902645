import { useCallback } from "react";

import { Link } from "uiKit/links";

import { ValidationErrorsLocalizationId } from "pages/login/errorNotification/localization";
import { SignInStore } from "pages/login/store";
import {
    AUTH_CODE_ERROR,
    EMAIL_ALREADY_TAKEN,
    EMAIL_DEACTIVATED,
    EMAIL_DOES_NOT_BELONG_TO_ACTIVE_USER,
    USER_IS_LOCKED,
} from "shared/api/constants/errors";
import { BadRequestErrorItem } from "shared/api/errors";
import { LocalizeFunction, useLocalize } from "shared/hooks/useLocalize";
import { useStore } from "shared/hooks/useStore";
import routeNames from "shared/routes/routeNames";

export const useLogInNotificationError = (): string | undefined => {
    const localize = useLocalize();

    const {
        store: { errors, isLocked },
    } = useStore(SignInStore);

    const lockTime = window.APP_SETTINGS.loginLockDurationMinutes;

    const getErrorMessage = useCallback(
        (error: BadRequestErrorItem, localize: LocalizeFunction) => {
            if (error.isLocked && !error.leftAttemptsCount) {
                return localize(ValidationErrorsLocalizationId.LockedUserError, {
                    Minutes: lockTime,
                });
            }

            const errorMessages: { [key: string]: string } = {
                [EMAIL_DOES_NOT_BELONG_TO_ACTIVE_USER]: localize(
                    ValidationErrorsLocalizationId.NotExistingAccountError,
                    {
                        SignUpLink: (
                            <Link
                                link={routeNames.PORTAL.SIGN_UP}
                                label={localize(ValidationErrorsLocalizationId.SignUpHere)}
                                typographyVariant="subtitle2"
                            />
                        ),
                    }
                ),
                [EMAIL_DEACTIVATED]: localize(ValidationErrorsLocalizationId.DeactivatedUserError, {
                    Link: (
                        <Link
                            link={`mailto:${localize(ValidationErrorsLocalizationId.DeactivatedUserErrorSupportEmail)}`}
                            label={localize(ValidationErrorsLocalizationId.DeactivatedUserErrorSupportEmail)}
                            typographyVariant="subtitle2"
                        />
                    ),
                }),
                [EMAIL_ALREADY_TAKEN]: localize(ValidationErrorsLocalizationId.AccountAlreadyExists),
                [AUTH_CODE_ERROR]: localize(ValidationErrorsLocalizationId.CodeAttemptsLeftError, {
                    Count: error.leftAttemptsCount,
                }),
                [USER_IS_LOCKED]: localize(ValidationErrorsLocalizationId.LockedUserError, {
                    Minutes: lockTime,
                }),
            };
            return errorMessages[error.code] || undefined;
        },
        [lockTime]
    );

    if (isLocked) {
        return localize(ValidationErrorsLocalizationId.LockedUserError, {
            Minutes: lockTime,
        });
    }

    for (const error of errors) {
        const errorMessage = getErrorMessage(error, localize);
        if (errorMessage) {
            return errorMessage;
        }
    }

    return errors.length ? errors[0].message : undefined;
};
