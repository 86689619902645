import { FunctionComponent, useCallback } from "react";
import { NumberFormatValues, NumericFormat } from "react-number-format";

import { InputComponentParams } from "uiKit/inputs/textField/interfaces";

interface CurrencyInputProps {
    inputRef: (el: HTMLInputElement) => void;
    onChange: (value: number) => void;
    name: string;
    value: number;
}

const CurrencyInput: FunctionComponent<CurrencyInputProps> = (props: CurrencyInputProps) => {
    const { inputRef: setInputRefInNumberFormat, onChange, value, ...other } = props;

    const onValueChange = useCallback((values: NumberFormatValues) => onChange(values.floatValue || 0), [onChange]);
    const isAllowed = useCallback(
        (values: NumberFormatValues) => values.floatValue === undefined || values.floatValue < Number.MAX_SAFE_INTEGER,
        []
    );

    return (
        <NumericFormat
            value={value || 0}
            {...other}
            getInputRef={setInputRefInNumberFormat}
            allowNegative={false}
            isAllowed={isAllowed}
            onValueChange={onValueChange}
            prefix="£"
            decimalScale={2}
            thousandSeparator
            fixedDecimalScale
        />
    );
};

export const CurrencyInputComponentParams: InputComponentParams = {
    inputComponent: CurrencyInput,
};
