export enum CheckInboxLocalizationId {
    CheckYourInbox = "sign-in-check-inbox.check_your_inbox_title",
    SentEmailParagraph = "sign-in-check-inbox.email_sent_paragraph",
    MagicLinkParagraph = "sign-in-check-inbox.it_has_a_magic_link_paragraph",
    DidntSeeTheEmail = "sign-in-check-inbox.didnt_see_the_email_paragraph",
    ResendEmailLink = "sign-in-check-inbox.resend_email_link",
    BackButton = "sign-in-check-inbox.back_button",
    StepPicture = "sign-in-check-inbox.step_picture",
    EmailHasBeenSent = "notifications.email_has_been_sent",
}
