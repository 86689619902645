import axios from "axios";

import { LocalizationMessages } from "shared/api/staticCms/models/localizationMessages";
import { LocalizationVersion } from "shared/api/staticCms/models/localizationVersion";
import { LocalizedComponentName } from "shared/localization/enums";

export const LOCALIZATION_URL_PREFIX = "api/";
export const CHECK_LOCALIZATION_VERSION_URL = `${LOCALIZATION_URL_PREFIX}content-version/version`;

const staticCmsApi = axios.create({
    baseURL: window.APP_SETTINGS.staticCmsApiBaseUri,
});

export const getLocalizationMessages = async (componentName: LocalizedComponentName): Promise<LocalizationMessages> => {
    const response = await staticCmsApi.get<LocalizationMessages>(`${LOCALIZATION_URL_PREFIX}${componentName}`, {
        params: {
            "pagination[limit]": -1,
        },
    });

    return response.data;
};

export const getLocalizationVersion = async (): Promise<LocalizationVersion> => {
    const response = await staticCmsApi.get<LocalizationVersion>(CHECK_LOCALIZATION_VERSION_URL);

    return response.data;
};
