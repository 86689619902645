import { action, makeObservable, observable, runInAction } from "mobx";

import { apiPutUiEmailVerify } from "shared/api/auth/ui/apiPutUiEmailVerify";

export class VerifyEmailStore {
    @observable public verificationEmail?: string;
    @observable public authToken?: string;

    constructor() {
        makeObservable(this);
    }

    @action.bound
    public async verifyEmailByToken(token: string): Promise<void> {
        const response = await apiPutUiEmailVerify({ token });
        runInAction(() => {
            this.verificationEmail = response.email;
            this.authToken = response.authToken;
        });
    }
}
