import { makeStyles } from "tss-react/mui";

import { TxtColor, mobileBreakpoint } from "shared/theme/defaultTheme";

export const useSignUpStepHeaderStyles = makeStyles()(theme => ({
    heading: {
        textAlign: "center",
        flex: "0 0 auto",
        marginTop: theme.spacing(6),
        position: "absolute",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            marginTop: theme.spacing(4),
        },
    },
    headingTypo: {
        color: TxtColor.Txt2,
    },
}));
