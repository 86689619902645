import { makeStyles } from "tss-react/mui";
import { AvatarStyleProps } from "uiKit/containers/avatar/interfaces";

import { applicationPalettes } from "shared/theme/defaultTheme";

export const useAvatarStyles = makeStyles<AvatarStyleProps>()((_, { width, height }) => ({
    avatar: {
        flexDirection: "column",
        position: "relative",
        width: width,
        height: height,
        border: `1px solid ${applicationPalettes.default[50]}`,
        backgroundColor: applicationPalettes.default["1"],
        "& .input-file": {
            width: "100%",
        },
        "& img": {
            height: "100%",
            width: "100%",
            position: "absolute",
            objectFit: "contain",
        },
    },
    avatarText: {
        color: applicationPalettes.primary[500],
    },
}));
