import { makeStyles } from "tss-react/mui";
import { ReactCodeInputStylesProps } from "uiKit/inputs/confirmationCode/interfaces";

import { TxtColor, applicationPalettes } from "shared/theme/defaultTheme";

export const useReactCodeInputStyles = makeStyles<ReactCodeInputStylesProps>()((theme, { showError }) => ({
    root: {
        width: "100%",
        display: "flex",
        gap: theme.spacing(2),
    },
    field: {
        width: 56,
        height: 48,
        padding: theme.spacing(1),
        border: "1px solid",
        borderColor: showError ? applicationPalettes.red[500] : TxtColor.Txt6,
        borderRadius: "8px",
        caretColor: "transparent",
        fontFamily: theme.typography.body1.fontFamily,
        color: theme.typography.body1.color,
        fontWeight: theme.typography.body1.fontWeight,
        fontSize: theme.typography.body1.fontSize,
        lineHeight: theme.typography.body1.lineHeight,
        letterSpacing: theme.typography.body1.letterSpacing,
        boxSizing: "border-box",
        flex: "1 1 auto",
        textAlign: "center",
        "&:focus": {
            outline: "none",
            border: "2px solid",
            caretColor: "transparent",
            borderColor: applicationPalettes.primary[300],
        },
        "&:hover:not(:focus)": {
            border: "1px solid",
            borderColor: applicationPalettes.primary[500],
        },
    },
}));

export const useConfirmationCodeFormControlStyles = makeStyles()(() => ({
    root: {
        display: "inline-flex",
        width: "100%",
        maxWidth: "275px",
    },
}));
