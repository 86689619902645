import { makeStyles } from "tss-react/mui";

import {
    TxtColor,
    applicationPalettes,
    montserratFontFamily,
    primaryApplicationFontFamily,
} from "shared/theme/defaultTheme";

const { Txt1, Txt3 } = TxtColor;

export const useLinkStyles = makeStyles()({
    wrapper: {
        maxWidth: "100%",
        display: "inline", // Do not change to "inline-block" cause it will cause the link text-overflow to be broken in the grids.
    },
    wrapperOverflowHidden: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    primary: {
        cursor: "pointer",
        textDecoration: "none",
        fontFamily: primaryApplicationFontFamily,
        maxWidth: "100%",
        "&.link1": {
            color: applicationPalettes.primary[500],
            borderBottom: "1px solid transparent",
            "&:hover": {
                color: applicationPalettes.primary[700],
                borderColor: applicationPalettes.primary[300],
            },
            "&:active": {
                color: applicationPalettes.primary[900],
            },
        },
        "&.link1subtitle": {
            fontFamily: montserratFontFamily,
            color: applicationPalettes.primary[500],
            borderBottom: "1px solid transparent",
            "&:hover": {
                color: applicationPalettes.primary[700],
                borderColor: applicationPalettes.primary[300],
            },
            "&:active": {
                color: applicationPalettes.primary[900],
            },
        },
        "&.link2": {
            color: Txt3,
            "&:hover": {
                color: applicationPalettes.primary[500],
            },
            "&:active": {
                color: applicationPalettes.primary[900],
            },
        },
        "&.link3": {
            fontFamily: montserratFontFamily,
            color: Txt1,
            "&:hover": {
                color: applicationPalettes.primary[500],
            },
            "&:active": {
                color: applicationPalettes.primary[700],
            },
        },
        "&.link-button": {
            borderBottom: "1px solid transparent",
        },
    },
    secondary: {
        cursor: "pointer",
        fontFamily: primaryApplicationFontFamily,

        "&.link1": {
            color: TxtColor.Txt2,
            textDecoration: "underline",
            textDecorationColor: TxtColor.Txt6,
            textDecorationThickness: "1px",
            textUnderlineOffset: "5px",

            "&:hover": {
                textDecoration: "none",
                color: applicationPalettes.primary[500],
            },
        },
    },
});
