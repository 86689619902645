import { ObjectSchema, object, string } from "yup";

import { LoginWithCredentialsLocalizationId } from "pages/login/steps/enterCredentials/localization";
import { LocalizeFunction } from "shared/hooks/useLocalize";
import { ValidationLocalizationId } from "shared/validation/validation.localization";
import { getValidatorValues } from "shared/validation/validation.valuesGetters";

export interface EnterCredentialsFormValues {
    userName: string;
    password: string;
}

export const createLoginWithCredentialsSchema = (
    localize: LocalizeFunction
): ObjectSchema<EnterCredentialsFormValues> =>
    object<EnterCredentialsFormValues>().shape({
        userName: string().required(
            localize(
                ValidationLocalizationId.NotEmptyValidatorKey,
                getValidatorValues(localize(LoginWithCredentialsLocalizationId.UsernameLabel))
            )
        ),
        password: string().required(
            localize(
                ValidationLocalizationId.NotEmptyValidatorKey,
                getValidatorValues(localize(LoginWithCredentialsLocalizationId.PasswordLabel))
            )
        ),
    });
